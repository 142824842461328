export default class PaymentMethodModel {
  id: number;
  description: string;
  name: string;
  picture: string;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.id = data.id;
    this.description = data.description;
    this.name = data.name;
    this.picture = data.pictureWebPath;
  }

  static setPaymentMethodModel(options) {
    return options.map((option) => new PaymentMethodModel(option));
  }
}
