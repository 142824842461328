import { optionsConstants } from '../constants/options.constants';

export const getOptionsRequest = (options: object) => ({
  type: optionsConstants.GET_OPTIONS_REQUEST,
  options,
});

export const getOptionsSuccess = (options: object) => ({
  type: optionsConstants.GET_OPTIONS_SUCCESS,
  options,
});

export const getOptionsFailure = (error: object) => ({
  type: optionsConstants.GET_OPTIONS_FAILURE,
  error,
});

export const getSessionOptionsRequest = (
  product_id: number,
  session_id: number
) => ({
  type: optionsConstants.GET_SESSION_OPTIONS_REQUEST,
  product_id,
  session_id,
});

export const getSessionOptionsSuccess = (options: object) => ({
  type: optionsConstants.GET_SESSION_OPTIONS_SUCCESS,
  options,
});

export const getSessionOptionsFailure = (error: object) => ({
  type: optionsConstants.GET_SESSION_OPTIONS_FAILURE,
  error,
});
