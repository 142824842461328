// URL PATTERN = /__PROJECT_BRAND__/__POS_SLUG__/__LOCALE__/
function parseUrl(pathname) {
  return pathname.split('/').slice(1);
}

export function getBaseUrl(pathname) {
  const element = parseUrl(pathname);

  return '/' + element.slice(0, 3).join('/');
}

export function getBaseUrlWithoutLang(pathname) {
  const element = parseUrl(pathname);

  return '/' + element.slice(0, 2).join('/');
}

export function removeBaseUrl(pathname) {
  const element = parseUrl(pathname);

  return '/' + element.slice(3).join('/');
}

export function getConfigParams(pathname) {
  const element = parseUrl(pathname);

  return {
    project: element[0],
    pos: element[1],
    _locale: element[2],
  };
}

export function handleChangeLanguage(pathname, lang) {
  const element = parseUrl(pathname);
  element[2] = lang;

  return '/' + element.join('/');
}
