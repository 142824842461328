export default class ShopPlacementMapModel {
  seatsioApiWorkspaceKey: string;
  seatsioApiRegion: string;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.seatsioApiWorkspaceKey = data.seatsioApiWorkspaceKey;
    this.seatsioApiRegion = data.seatsioApiRegion;
  }
}
