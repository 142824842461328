import { Cookies } from 'react-cookie';
import { getBaseUrlWithoutLang } from './parseUrl';
const cookies = new Cookies();

/*-----------------------Basket session--------------------------*/
export const getBasketCookie = (): {
  id: string;
  signature: string;
} | null => {
  return getBasketStorage();
};

export const setBasketCookie = (signature: string, id: string): void => {
  const date = new Date();
  const nextMonth = date.setMonth(date.getMonth() + 1);

  localStorage.setItem(
    getBasketKey(),
    JSON.stringify({ signature, id, expires: new Date(nextMonth) })
  );
};

export const removeBasketCookie = (): void => {
  localStorage.removeItem(getBasketKey());
};

function getBasketKey() {
  return 'basket+' + getBaseUrlWithoutLang(window.location.pathname);
}

function decodeBasketToken(jsonToken: string) {
  try {
    const basketToken = JSON.parse(jsonToken);
    if (typeof basketToken !== 'object') {
      removeBasketToken();
      return null;
    }
    const now = new Date();
    if (basketToken.expires >= now) {
      removeBasketToken();
      return null;
    }
    return basketToken;
  } catch (e) {
    console.error('Basket token failed to decode', e);
    removeBasketToken();
    return null;
  }
}

function getBasketStorage() {
  const basketCookies = cookies.get('basket_session') || null;
  if (basketCookies && typeof basketCookies === 'object') {
    setBasketCookie(basketCookies?.signature, basketCookies?.id);
    cookies.remove('basket_session', {
      path: getBaseUrlWithoutLang(window.location.pathname),
    });
  }
  const jsonToken = localStorage.getItem(getBasketKey());
  if (!jsonToken) {
    return null;
  }
  return decodeBasketToken(jsonToken);
}

export const removeBasketToken = () => {
  localStorage.removeItem(getBasketKey());
};
