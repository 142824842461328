import React, { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useMatch,
} from 'react-router-dom';
import ScrollToTop from '../../services/ScrollToTop';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {
  initializeGoogleAnalytics,
  initializeFacebookPixel,
} from '../../services/tracking';
import { ErrorBoundary } from 'react-error-boundary';
import { setShopConfig, shopConfig } from '../../Globals/ShopConfig';
import Page, { PageProps } from './Page';
import { shopConstants } from '../../Constants/Constants';
import { get, getUrl } from '../../services/httpService';
import ShopConfigModel from '../../Models/ShopConfig/ShopConfigModel';
import { ConfigProvider } from 'antd';
import { getTempLocale } from '../../services/getTempLocale';
import LoadingShop from '../../components/LoadingShop/LoadingShop';
import initTranslations from '../../services/initTranslations';
import initColors from '../../services/initColors';
import { getConfigParams } from '../../services/parseUrl';
import Translator from '../../services/translator';
import {
  getPreviousPath,
  getShopTokenStorage,
} from '../../services/handleStorage';
import { existTokensInStorage } from '../../services/handleTokens';
import history from '../../services/history';
let antdLocale = null;

import BasketPage from '../Basket';
import { addCustomCssToShop } from '../../services/addCustomCss';
const Products = lazy(() => import('../Products'));
const AuthenticationPage = lazy(() => import('../Authentication'));
const AccommodationDetail = lazy(() => import('../Details/Accommodation'));
const TicketDetail = lazy(() => import('../Details/Tickets'));
const Cms = lazy(() => import('../../components/Footer/Cms'));
const PaymentConfirmation = lazy(
  () => import('../Confirmation/Payment/PaymentConfirmation')
);
const OrderConfirmation = lazy(
  () => import('../Confirmation/Order/OrderConfirmation')
);
const EventPage = lazy(() => import('../Event'));
const Checkout = lazy(() => import('../Checkout/Checkout'));

const RoutesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showShop, setShowShop] = useState(false);
  const [showShopErrorMessage, setShowShopErrorMessage] = useState('');
  const isCheckoutUrl = useMatch('/checkout');

  const getShopConfig = useCallback(() => {
    const HOSTNAME = process.env.REACT_APP_URL_BACKEND;

    get(
      getUrl(
        HOSTNAME + '/config/shop',
        getConfigParams(window.location.pathname)
      )
    )
      .then((response) => {
        setShopConfig(new ShopConfigModel(response));
        initTranslations(shopConfig.locale.language);
        initColors(shopConfig.branding);
        antdLocale = require('antd/lib/locale/' +
          getTempLocale() +
          '.js').default;

        initializeGoogleAnalytics(shopConfig.analytics.googleAnalytics);
        initializeFacebookPixel(shopConfig.analytics.facebookPixel);

        shopConfig.locale.setAntdLocale(antdLocale);

        addCustomCssToShop(shopConfig.branding.customCss);
        setShowShop(true);
      })
      .catch((error) => {
        if (error.body?.message) {
          setShowShopErrorMessage(error.body.message);
        } else {
          setShowShopErrorMessage(
            Translator.trans('error.internal_error.title')
          );
        }

        console.error('Get config error : ', error);
      });
  }, []);

  useEffect(() => {
    setShowShopErrorMessage('');
    getShopConfig();
  }, [getShopConfig]);

  useEffect(() => {
    history.navigate = navigate;
    history.location = location;
  }, [navigate, location]);

  const CheckRoute = (props: PageProps): React.ReactElement => {
    const shopToken = getShopTokenStorage();
    if (shopConfig.pos.closed) {
      return <Navigate to="/shop/closed" />;
    }

    if (shopConfig.project.canceled) {
      return <Navigate to="/event/canceled" />;
    }

    if (shopConfig.pos.requirePassword && !shopToken) {
      return <Navigate to="/shop/private" />;
    }

    return <Page translation={props.translation}>{props.children}</Page>;
  };

  const AuthRoute = (props: PageProps): React.ReactElement => {
    if (existTokensInStorage()) {
      const previousPath = getPreviousPath();
      return <Navigate to={previousPath} />;
    } else {
      return <Page translation={props.translation}>{props.children}</Page>;
    }
  };

  const getDefaultUrl = (): [boolean, string] => {
    const getUrl = new URLSearchParams(window.location.search);
    const currentAction = getUrl.get('action');
    getUrl.delete('action');
    const searchParams = getUrl.toString();

    switch (currentAction) {
      case 'forgot-password':
        return [
          false,
          '/auth/' + shopConstants.RESETPASSWORD + '?' + searchParams,
        ];
      case 'order-success':
        return [false, '/payment/success?' + searchParams];
      case 'payment-checkout':
        return [false, '/checkout?' + searchParams];
      case 'payment-success':
        return [false, '/payment/success?' + searchParams];
      case 'payment-fail':
        return [false, '/payment/failure?' + searchParams];
      case 'product':
        return [false, '/tickets?' + searchParams];
    }
    return [false, '/tickets?' + searchParams];
  };

  let [noWrapper, defaultUrl] = getDefaultUrl();

  if (isCheckoutUrl) {
    noWrapper = true;
  }

  return showShop ? (
    <ConfigProvider
      locale={antdLocale}
      theme={{
        token: {
          colorPrimary: shopConfig?.branding.colorPrimary,
        },
      }}
    >
      <ScrollToTop>
        <ErrorBoundary
          FallbackComponent={(exception) => (
            <ErrorPage exception={exception} error={exception.error.message} />
          )}
        >
          <div className={'shop-container'}>
            {noWrapper || <Header />}
            <Suspense fallback={<LoadingShop isLazy />}>
              <Routes>
                <Route
                  path="/tickets"
                  element={
                    <CheckRoute>
                      <Products />
                    </CheckRoute>
                  }
                />

                <Route
                  path="/payment/:response"
                  element={
                    <CheckRoute translation={'page.titles.payment'}>
                      <PaymentConfirmation />
                    </CheckRoute>
                  }
                />

                <Route
                  path="/order/:response"
                  element={
                    <CheckRoute translation={'page.titles.order'}>
                      <OrderConfirmation />
                    </CheckRoute>
                  }
                />

                <Route
                  path="/basket"
                  element={
                    <CheckRoute translation={'page.titles.basket'}>
                      <BasketPage />
                    </CheckRoute>
                  }
                />

                <Route
                  path="/detail/accommodation/:id"
                  element={
                    <CheckRoute translation={'page.titles.detail'}>
                      <AccommodationDetail />
                    </CheckRoute>
                  }
                />

                <Route
                  path="/detail/ticket/:id"
                  element={
                    <CheckRoute translation={'page.titles.detail'}>
                      <TicketDetail />
                    </CheckRoute>
                  }
                />

                {shopConfig.pos.closed && (
                  <Route
                    path="/shop/closed"
                    element={
                      <Page translation={'page.titles.closed'}>
                        <EventPage isClosed />
                      </Page>
                    }
                  />
                )}
                {shopConfig.project.canceled && (
                  <Route
                    path="/event/canceled"
                    element={
                      <Page translation={'page.titles.canceled'}>
                        <EventPage isCanceled />
                      </Page>
                    }
                  />
                )}
                {shopConfig.pos.requirePassword && (
                  <Route
                    path="/shop/private"
                    element={
                      <Page translation={'page.titles.private'}>
                        <EventPage isPrivate />
                      </Page>
                    }
                  />
                )}

                <Route
                  path="/auth/:loginType"
                  element={
                    <AuthRoute translation={'page.titles.auth'}>
                      <AuthenticationPage />
                    </AuthRoute>
                  }
                />

                <Route
                  path="/page/:page"
                  element={
                    <Page translation={'page.titles.more'}>
                      <Cms />
                    </Page>
                  }
                />

                <Route path="/checkout" element={<Checkout />} />

                <Route path="/*" element={<Navigate to={defaultUrl} />} />
              </Routes>
            </Suspense>
            {noWrapper || <Footer />}
          </div>
        </ErrorBoundary>
      </ScrollToTop>
    </ConfigProvider>
  ) : (
    <LoadingShop error={showShopErrorMessage} />
  );
};

export default RoutesPage;
