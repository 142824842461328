import React from 'react';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import EmisysButton from '../EmisysButton/EmisysButton';
import Translator from '../../services/translator';
import './Modal.css';

interface ModalFilterDateProps {
  isOpen: boolean;
  handleClose: () => void;
}

const ModalTimer = (props: ModalFilterDateProps) => (
  <Modal
    title={ModalHeader(props)}
    closable={false}
    open={props.isOpen}
    onCancel={props.handleClose}
    footer={null}
    width={960}
  >
    <div className="modalTimer">
      <div className="timerExpiredButtonComponent">
        <div className="timerExpiredButton">
          <EmisysButton onClick={props.handleClose}>
            {Translator.trans('order.button.back_to_products')}
          </EmisysButton>
        </div>
      </div>
    </div>
  </Modal>
);

const ModalHeader = (props: ModalFilterDateProps) => (
  <>
    <div className="title-modal-timer-container">
      <h2>{Translator.trans('timer.expired')}</h2>
    </div>

    <Button
      type="primary"
      shape="circle"
      className="close-button"
      icon={<CloseOutlined />}
      onClick={props.handleClose}
    />
  </>
);

export default ModalTimer;
