import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../services/history';
import { Row, Col } from 'antd';
import BasketBreadCrumb from '../../components/BasketBreadCrumb/BreadCrumb';
import Container from '../../components/Container/Container';
import HeaderShop from '../../components/HeaderShop/HeaderShop';
import Wallpaper from '../../components/Wallpaper/Wallpaper';
import { getBasketRequest } from '../../redux/actions/basket.actions';
import { checkReduxResponse, getUrl, post } from '../../services/httpService';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { openNotificationError } from '../../components/Notification/Notification';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import { BasketStatusConstant } from '../../Constants/Constants';
import getBasketSession from '../../services/basketSession';
import './index.css';

const BasketPage = () => {
  const dispatch = useDispatch();

  const [cancelLoading, setCancelLoading] = useState(false);
  const [isBasketLoading, setIsBasketLoading] = useState(true);
  const [isBasketEditable, setIsBasketEditable] = useState(true);
  const shopName = shopConfig.pos.shopName;
  const bannerPictureUrl = shopConfig.branding.bannerPictureUrl;

  const currentBasket = useSelector((state: any) => state.basket.currentBasket);

  const getBasket = useCallback(() => {
    dispatch(getBasketRequest());
  }, [dispatch]);

  useEffect(() => {
    getBasket();
  }, [getBasket]);

  useEffect(() => {
    setIsBasketLoading(currentBasket?.loading);
    if (checkReduxResponse(currentBasket, 'basket')) {
      if (
        currentBasket.basket.status !== BasketStatusConstant.cancel &&
        currentBasket.basket.status !== BasketStatusConstant.payment_accept &&
        currentBasket.basket.status !== BasketStatusConstant.expired
      ) {
        setIsBasketEditable(currentBasket.basket.editable);
      }
    }
  }, [
    currentBasket?.basket?.status,
    currentBasket?.basket?.editable,
    currentBasket,
  ]);

  const handleCancelOrder = useCallback(() => {
    const url = shopConfig.router.orderCancelUrl;
    setCancelLoading(true);
    post(getUrl(url, getBasketSession()))
      .then((response) => {
        history.navigate({
          pathname: '/order/cancel',
          search: '?id=' + response.id + '&signature=' + response.signature,
        });
      })
      .catch((error) => {
        openNotificationError(
          Translator.trans('error.internal_error.title'),
          error.body?.message
        );
      })
      .finally(() => {
        setCancelLoading(false);
      });
  }, []);

  return (
    <Wallpaper>
      <div className="shop-full-container">
        <Row gutter={16}>
          <Col className="gutter-row" xs={24} sm={24}>
            {bannerPictureUrl && (
              <div className="product-review-banner">
                <Container>
                  <img
                    className="banner"
                    src={bannerPictureUrl}
                    alt={'shop banner'}
                  />
                </Container>
              </div>
            )}
            <HeaderShop title={shopName}>
              <div className="basket-header-link">
                {!isBasketLoading &&
                  (isBasketEditable ? (
                    <Link to="/tickets">
                      {Translator.trans('order.button.back_to_products')}
                    </Link>
                  ) : (
                    <div onClick={cancelLoading ? null : handleCancelOrder}>
                      {cancelLoading ? <LoadingOutlined /> : <DeleteOutlined />}

                      <span className={'cancel-order-button'}>
                        {Translator.trans('order.button.cancel')}
                      </span>
                    </div>
                  ))}
              </div>
            </HeaderShop>
          </Col>
        </Row>
        <BasketBreadCrumb basketEditable={isBasketEditable} />
      </div>
    </Wallpaper>
  );
};

export default BasketPage;
