import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { orderConstants } from '../constants/order.constants';
import { get, getUrl, post } from '../../services/httpService';
import {
  getOrderOverviewFailure,
  getOrderOverviewSuccess,
  postOrderConfirmFailure,
  postOrderConfirmSuccess,
  getPendingOrderFailure,
  getPendingOrderSuccess,
} from '../actions/order.actions';
import { shopConfig } from '../../Globals/ShopConfig';
import getBasketSession from '../../services/basketSession';

export async function getOrderOverviewFetch(arg) {
  const url = shopConfig.router.orderReviewUrl;
  return await get(getUrl(url, arg.basketSession));
}

export async function postOrderConfirmFetch(arg) {
  const url = shopConfig.router.orderConfirmUrl;
  return await post(getUrl(url, arg.basketSession), arg.payload);
}

export async function getPendingOrderFetch() {
  const url = shopConfig.router.orderPendingListUrl;
  return await get(getUrl(url));
}

export function* fetchGetOrderOverview() {
  try {
    const order = yield call(getOrderOverviewFetch, {
      basketSession: getBasketSession(),
    });
    yield put(getOrderOverviewSuccess(order));
  } catch (error) {
    yield put(getOrderOverviewFailure(error));
  }
}

export function* fetchPostOrderConfirm(action) {
  try {
    const order = yield call(postOrderConfirmFetch, {
      payload: action.order,
      basketSession: getBasketSession(),
    });
    yield put(postOrderConfirmSuccess(order));
  } catch (error) {
    yield put(postOrderConfirmFailure(error));
  }
}

export function* fetchGetPendingOrder() {
  try {
    const order = yield call(getPendingOrderFetch);
    yield put(getPendingOrderSuccess(order));
  } catch (error) {
    yield put(getPendingOrderFailure(error));
  }
}

export function* getOrderOverview() {
  yield takeLatest(
    orderConstants.GET_ORDER_OVERVIEW_REQUEST,
    fetchGetOrderOverview
  );
}

export function* postOrderConfirm() {
  yield takeLatest(
    orderConstants.POST_ORDER_CONFIRM_REQUEST,
    fetchPostOrderConfirm
  );
}

export function* getPendingOrder() {
  yield takeLatest(
    orderConstants.GET_PENDING_ORDER_REQUEST,
    fetchGetPendingOrder
  );
}

export default function* orderSaga() {
  yield all([
    fork(getOrderOverview),
    fork(postOrderConfirm),
    fork(getPendingOrder),
  ]);
}
