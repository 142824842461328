import { formTypeConstant } from '../../Constants/Constants';
import { shopConfig } from '../../Globals/ShopConfig';

export default class QuestionModel {
  attributes: any;
  choices: any;
  help: string | null;
  id: number | null;
  label: string;
  maxLength: number | null;
  multiple: boolean;
  placeholder: string | null;
  required: boolean;
  title: boolean;
  titleDepth: number;
  titleLevel: 'title' | 'subtitle' | '';
  type: keyof typeof formTypeConstant | '';
  value: any;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.attributes = data.attributes;
    this.choices = data.choices;
    this.help = data.help;
    this.id = data.id;
    this.label = data.label;
    this.maxLength = data.maxLength;
    this.multiple = data.multiple;
    this.placeholder = data.placeholder;
    this.required = data.required;
    this.title = data.title;
    this.titleDepth = data.titleDepth;
    this.titleLevel = data.titleLevel;
    this.type = data.type;
    this.value = this.initValue(data.value);
  }

  private initValue = (value) => {
    let newValue = value;
    if (this.type === formTypeConstant.CountryType) {
      newValue = value !== null ? value : shopConfig.locale.countryCode;
    } else if (this.type === formTypeConstant.UserPictureType) {
      newValue = {
        image: value,
      };
    } else {
      newValue = value !== null ? value : '';
    }

    return newValue;
  };
}
