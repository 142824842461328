export default class ShopProjectModel {
  id: number;
  name: string;
  audienceStart: Date;
  audienceStop: Date;
  canceled: boolean;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.audienceStart = new Date(data.audienceStart);
    this.audienceStop = new Date(data.audienceStop);
    this.canceled = data.canceled;
  }
}
