import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer } from 'antd';
import DrawerCalendarSession from '../Calendar/DrawerCalendarSession';
import TicketOptions from '../MultipleChoice/TicketOptions';
import {
  getOptionsRequest,
  getSessionOptionsRequest,
} from '../../redux/actions/options.actions';
import { getSessionsRequest } from '../../redux/actions/sessions.actions';
import TicketPlacement from '../MultipleChoice/TicketPlacement';
import { checkReduxResponse } from '../../services/httpService';
import SessionList from '../Sessions/SessionList';
import SessionFilters from '../Sessions/SessionFilters';
import { createDateSession, filterSession } from '../../services/services';
import DrawerHeader from './DrawerHeader';
import DrawerFooter from './DrawerFooter';
import { DownOutlined } from '@ant-design/icons';
import { formatToFilterDate } from '../../services/formatDate';
import ReactMarkdown from 'react-markdown';
import Translator from '../../services/translator';
import OptionModel from '../../Models/OptionModel';
import './Drawer.css';

class DrawerProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDate: null,
      showCalender: true,
      showSchedule: true,
      loadingSessions: false,
      loadingOptions: false,
      loadingButton: false,
      disableButton: true,
      sessionList: [],
      currentOption: null,
      currentFormule: null,
      quantityMinPerSale: 1,
      selectedSeats: [],
      currentSession: null,
      filters: null,
      activeFilters: null,
      dateSession: null,
      showSessions: {},
      selectedSessions: {},
      selectedOptions: [],
      totalAllPrice: 0,
      showArrowGoToSessionsSelected: false,
      loadedSessionOptions: [],
    };
  }

  componentDidMount() {
    this.setProduct(this.props.product);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentBasket !== this.props.currentBasket) {
      if (!this.props.currentBasket.loading) {
        this.setState({ loadingButton: false });
      }
      if (checkReduxResponse(this.props.currentBasket, 'basket')) {
        this.props.onCancel();
      }
    }

    if (prevProps.sessionsList !== this.props.sessionsList) {
      this.setState({ loadingSessions: this.props.sessionsList.loading });
      if (checkReduxResponse(this.props.sessionsList, 'sessions')) {
        this.setState({
          sessionList: this.props.sessionsList.sessions,
          showSessions: {},
          currentSession:
            this.props.sessionsList.sessions.length > 0
              ? this.props.sessionsList.sessions[0]
              : null,
        });
      }
      if (this.props.sessionsList.loading) {
        this.setState({
          disableButton: true,
          sessionList: [],
          currentOption: null,
        });
      }
    }

    if (prevProps.filterSession !== this.props.filterSession) {
      this.setState({ filters: this.props.filterSession });
    }

    if (prevProps.dateSession !== this.props.dateSession) {
      this.setState({ dateSession: this.props.dateSession });
      this.handleShowCalendar(this.props.dateSession);
    }

    if (prevProps.optionsList !== this.props.optionsList) {
      this.setState({ loadingOptions: this.props.optionsList.loading });
      if (
        checkReduxResponse(this.props.optionsList, 'options') &&
        this.props.optionsList.options.length !== 0
      ) {
        this.setState({ currentOption: this.props.optionsList.options });
      }
      if (this.props.optionsList.loading) {
        this.setState({
          disableButton: true,
          sessionList: [],
          currentOption: null,
        });
      }
    }

    if (this.props.sessionOptionsList !== prevProps.sessionOptionsList) {
      if (checkReduxResponse(this.props.sessionOptionsList, 'options')) {
        this.setSessionOptions(
          this.props.sessionOptionsList.options,
          this.state.currentSession
        );
      }
    }
  }

  /**
   @param {SessionOptionModel[]} sessionOptionsList
   @param {SessionModel} session
   */
  setSessionOptions = (sessionOptionsList, session) => {
    const { product } = this.props;
    let newSessionOptions = [...this.state.loadedSessionOptions];

    let sessionOptionItem = newSessionOptions.find(
      (item) => item.sessionId === session.id && item.productId === product.id
    );

    if (!sessionOptionItem) {
      newSessionOptions.push({
        sessionId: session.id,
        productId: product.id,
        options: sessionOptionsList,
      });
    }

    this.setState({ loadedSessionOptions: newSessionOptions });
  };

  /**
   @param {ProductModel} product
   @param {SessionModel} session
   */
  openSessionOptionCollapse = (product, session) => {
    this.props.getSessionOptions(product.id, session.id);
    this.setState({ currentSession: session });
  };

  /**
   @param {ProductModel} product
   */
  setProduct = (product) => {
    const quantityMinPerSale = product.quantityMinPerSale
      ? product.quantityMinPerSale
      : 1;

    if (product.hasOptions) {
      this.props.getOptions(product.id);
    }

    if (product.hasSessions) {
      this.props.getSessions(product.id);
    }

    this.setState({ quantityMinPerSale: quantityMinPerSale });
  };

  /**
   @param {object} dates
   */
  handleShowCalendar = (dates) => {
    if (dates) {
      let dateKey = Object.keys(dates);
      let dateValues = Object.values(dates);

      if (dateKey.length < 2) {
        this.setState({ showCalender: false });
      }

      if (dateKey.length < 2 && dateValues.length < 2) {
        dateValues.forEach((value) => {
          if (Object.keys(value).length < 2) {
            this.setState({ showSchedule: false });
          }
        });
      }
    }
  };

  getPrice = () => {
    const { currentFormule } = this.state;
    const { product } = this.props;

    return currentFormule ? currentFormule.formule.price : product.price.amount;
  };

  /**
   * @param {object} seat https://docs.seats.io/docs/renderer/events-onobjectselected
   */
  onSelectSeats = (seat) => {
    //TODO: fix on selectSeats
    let newArraySeats = [...this.state.selectedSeats, seat];

    let toReplace = {};

    this.setState({
      selectedSeats: newArraySeats,
      disableButton: newArraySeats.length === 0,
    });
    toReplace.placementUnits = newArraySeats.map(({ id }) => id);
    toReplace.placementHoldToken = seat.chart.holdToken;
  };

  /**
   * @param {object} seat https://docs.seats.io/docs/renderer/events-onobjectdeselected
   */
  onDeselectSeats = (seat) => {
    let newArraySeats = this.state.selectedSeats.filter(
      (element) => element.id !== seat.id
    );

    this.setState({
      selectedSeats: newArraySeats,
      disableButton: newArraySeats.length === 0,
    });
  };

  /**
   @param {string} date
   */
  onClickDay = (date) => {
    this.setState({
      activeDate: date,
      showSessions: {},
      showArrowGoToSessionsSelected: false,
    });
  };

  /**
   @param {SessionModel[]} sessions
   */
  onClickSchedule = (sessions) => {
    const currentActiveSessions = { ...this.state.showSessions };

    sessions.forEach((session) => {
      let keyDate = formatToFilterDate(session.dateStart);
      if (currentActiveSessions[keyDate]) {
        const index = currentActiveSessions[keyDate].findIndex(
          (current) => current.id === session.id
        );

        if (index > -1) {
          currentActiveSessions[keyDate].splice(index, 1);
        } else {
          currentActiveSessions[keyDate].push(session);
        }
      } else {
        currentActiveSessions[keyDate] = [session];
      }
    });

    this.setState({ showSessions: currentActiveSessions });
  };

  /**
   @param {SessionModel} session
   @param {number} quantity
   */
  handleChangeQtySession = (session, quantity) => {
    const newSessionList = this.state.sessionList.map((obj) => {
      if (obj.id === session.id) {
        obj.quantity = quantity;
        obj.totalPrice = this.getPrice() * quantity;
      }
      return obj;
    });

    let newShowSession = { ...this.state.showSessions };
    Object.keys(newShowSession).forEach((obj) => {
      if (newShowSession[obj].id === session.id) {
        newShowSession[obj].quantity = quantity;
        newShowSession[obj].totalPrice = this.getPrice() * quantity;
      }
    });

    this.setState(
      {
        sessionList: newSessionList,
        showSessions: newShowSession,
      },
      () => {
        this.calculateTotalSelectedItems(
          this.state.sessionList,
          this.state.selectedOptions
        );
      }
    );
  };

  /**
    @param {OptionModel} item
    @param {number} quantity
    @param {SessionModel} session
   */
  handleChangeQtyOption = (item, quantity, session) => {
    let newSelectedOption = [...this.state.selectedOptions];
    let newItem = { ...item };

    if (session) {
      let index = newSelectedOption.findIndex(
        (option) =>
          option.formule.id === item.formule.id &&
          option.sessionId === session.id
      );
      if (index > -1) {
        if (quantity === 0) {
          newSelectedOption.splice(index, 1);
        } else {
          newSelectedOption[index].quantity = quantity;
          newSelectedOption[index].totalPrice = item.formule.price * quantity;
        }
      } else {
        newItem.quantity = quantity;
        newItem.totalPrice = item.formule.price * quantity;
        newItem.sessionId = session.id;

        newSelectedOption.push(new OptionModel(newItem));
      }
    } else {
      let index = newSelectedOption.findIndex(
        (option) => option.formule.id === item.formule.id
      );
      if (index > -1) {
        if (quantity === 0) {
          newSelectedOption.splice(index, 1);
        } else {
          newSelectedOption[index].quantity = quantity;
          newSelectedOption[index].totalPrice = item.formule.price * quantity;
        }
      } else {
        newItem.quantity = quantity;
        newItem.totalPrice = item.formule.price * quantity;

        newSelectedOption.push(new OptionModel(newItem));
      }
    }

    this.setState({ selectedOptions: newSelectedOption }, () => {
      this.calculateTotalSelectedItems(
        this.state.sessionList,
        this.state.selectedOptions
      );
    });
  };

  /**
   @param {string[]} event
   @param {string} key
   */
  onHandleFilter = (event, key) => {
    const newActiveFilters = { ...this.state.activeFilters };
    newActiveFilters[key] = event;

    this.setState({ activeFilters: newActiveFilters });

    const filteredSession = filterSession(
      this.state.sessionList,
      newActiveFilters
    );

    this.setState({ dateSession: filteredSession });
  };

  /**
   @param {SessionModel[]} sessions
   @param {OptionModel[]} options
   */
  calculateTotalSelectedItems = (sessions, options) => {
    const sessionWithQty = sessions.filter((session) => session.quantity > 0);

    let totalPrice = 0;

    if (options.length > 0) {
      options.forEach((option) => {
        totalPrice += option.totalPrice;
      });
    } else {
      if (sessionWithQty.length > 0) {
        sessionWithQty.forEach((session) => {
          totalPrice += session.totalPrice;
        });
      }
    }

    this.setState({
      totalAllPrice: totalPrice,
      selectedSessions: createDateSession(sessionWithQty),
    });
  };

  goToSessionsSelected = () => {
    document
      .getElementById('sessions-list-selected')
      .scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.setState({ showArrowGoToSessionsSelected: false });
  };

  render() {
    const { product } = this.props;
    const {
      loadingOptions,
      loadingSessions,
      selectedSeats,
      showSessions,
      currentOption,
      filters,
      dateSession,
      totalAllPrice,
      selectedOptions,
      selectedSessions,
      activeDate,
      showArrowGoToSessionsSelected,
      loadedSessionOptions,
      showCalender,
      showSchedule,
    } = this.state;

    return (
      <Drawer
        rootClassName={'product-drawer-container'}
        title={
          <DrawerHeader onCancel={this.props.onCancel} product={product} />
        }
        footer={
          <DrawerFooter
            totalAllPrice={totalAllPrice}
            options={selectedOptions}
            sessions={selectedSessions}
            product={product}
          />
        }
        onClose={this.props.onCancel}
        open={this.props.isOpen}
        closable={false}
      >
        <Spin spinning={loadingOptions || loadingSessions}>
          <div className={'drawer-body-session'}>
            {product.picture && (
              <div className={'product-drawer-image'}>
                <img alt={product.name} src={product.picture} loading="lazy" />
              </div>
            )}

            {product.description !== '' && (
              <div>
                <div className="drawer-subtitle">
                  {Translator.trans('product.description')}
                </div>
                <ReactMarkdown>{product.description}</ReactMarkdown>
              </div>
            )}

            {!loadingOptions && !loadingSessions && (
              <>
                {dateSession ? (
                  <>
                    <div>
                      {(showCalender || showSchedule) && (
                        <div className="drawer-subtitle">
                          {Translator.trans('calendar.select_date')}
                        </div>
                      )}

                      {filters && showCalender && (
                        <SessionFilters
                          filters={filters}
                          onHandleFilter={this.onHandleFilter}
                        />
                      )}
                      <DrawerCalendarSession
                        showCalender={showCalender}
                        showSchedule={showSchedule}
                        filterDate={dateSession}
                        onClickDay={this.onClickDay}
                        onClickSchedule={this.onClickSchedule}
                        showArrow={(show) =>
                          this.setState({
                            showArrowGoToSessionsSelected: show,
                          })
                        }
                      />
                      {showArrowGoToSessionsSelected ? (
                        <div className={'go-top-sessions-selected'}>
                          <DownOutlined
                            onClick={this.goToSessionsSelected}
                            className={'go-top-sessions-selected-arrow'}
                          />
                        </div>
                      ) : null}
                    </div>

                    <div id={'sessions-list-selected'}>
                      {Object.keys(showSessions).length > 0 ? (
                        <SessionList
                          list={showSessions}
                          unitPrice={this.getPrice()}
                          product={product}
                          handleChangeQtySession={this.handleChangeQtySession}
                          handleChangeQtyOption={this.handleChangeQtyOption}
                          option={currentOption}
                          selectedOptions={selectedOptions}
                          loadedSessionOptions={loadedSessionOptions}
                          setSessionOptions={this.setSessionOptions}
                          openSessionOptionCollapse={
                            this.openSessionOptionCollapse
                          }
                          sessionOptionsList={this.props.sessionOptionsList}
                        />
                      ) : (
                        <div className={'empty-session-list'}>
                          {Translator.trans('session.empty_list')}
                        </div>
                      )}
                    </div>

                    <div>
                      {Object.keys(selectedSessions).length > 0 && (
                        <SessionList
                          activeDate={activeDate}
                          list={selectedSessions}
                          unitPrice={this.getPrice()}
                          product={product}
                          handleChangeQtySession={this.handleChangeQtySession}
                          handleChangeQtyOption={this.handleChangeQtyOption}
                          option={currentOption}
                          selectedOptions={selectedOptions}
                          loadedSessionOptions={loadedSessionOptions}
                          setSessionOptions={this.setSessionOptions}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  currentOption && (
                    <TicketOptions
                      option={currentOption}
                      product={product}
                      selectedOptions={selectedOptions}
                      session={null}
                      handleChangeQtySession={this.handleChangeQtySession}
                      handleChangeQtyOption={this.handleChangeQtyOption}
                    />
                  )
                )}
              </>
            )}

            {this.state.currentSession !== null &&
              this.state.currentSession.placementEventKey !== null && (
                <TicketPlacement
                  product={product}
                  onDeselectSeats={this.onDeselectSeats}
                  onSelectSeats={this.onSelectSeats}
                  quantityMinPerSale={this.state.quantityMinPerSale}
                  placementEventKey={
                    this.state.currentSession.placementEventKey
                  }
                  selectedSeats={selectedSeats}
                />
              )}
          </div>
        </Spin>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  currentBasket: state.basket.currentBasket,
  sessionsList: state.sessions.sessionsList,
  filterSession: state.sessions.filterSession,
  dateSession: state.sessions.dateSession,
  optionsList: state.options.optionsList,
  sessionOptionsList: state.options.sessionOptionsList,
});

const mapDispatchToProps = (dispatch) => ({
  getOptions: (session) => dispatch(getOptionsRequest(session)),
  getSessions: (session) => dispatch(getSessionsRequest(session)),
  getSessionOptions: (product, session) =>
    dispatch(getSessionOptionsRequest(product, session)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerProduct);
