import React from 'react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { shopConstants } from '../../../Constants/Constants';
import { shopConfig } from '../../../Globals/ShopConfig';
import Translator from '../../../services/translator';
import history from '../../../services/history';
import { addPreviousPathStorage } from '../../../services/handleStorage';
import './index.css';

const userDropdown = (userProfile, onLogOut) => {
  const goToSignIn = () => {
    addPreviousPathStorage();
    history.navigate('/auth/' + shopConstants.SIGNIN);
  };

  const goToSignUp = () => {
    addPreviousPathStorage();
    history.navigate('/auth/' + shopConstants.SIGNUP);
  };

  return userProfile
    ? [
        {
          key: 'profile',
          label: (
            <a href={shopConfig.router.userAccountUrl}>
              <div className={'menu-btn'}>
                <UserOutlined />
                <span>{Translator.trans('account.profile.my_account')}</span>
              </div>
            </a>
          ),
        },
        {
          key: 'logout',
          label: (
            <div className={'menu-btn menu-logout-btn'} onClick={onLogOut}>
              <LogoutOutlined />
              <span>{Translator.trans('auth.action.logout')}</span>
            </div>
          ),
        },
      ]
    : [
        {
          key: 'login',
          label: (
            <div onClick={goToSignIn}>
              {Translator.trans('auth.action.signIn')}
            </div>
          ),
        },
        {
          key: 'register',
          label: (
            <div onClick={goToSignUp}>
              {Translator.trans('auth.action.signUp')}
            </div>
          ),
        },
      ];
};

export default userDropdown;
