import { shopConfig } from '../Globals/ShopConfig';

export function getTempLocale() {
  let tempLocale = shopConfig.locale.locale.replace('-', '_');
  /*
   * Not every locale exists on disk. Map missing locale to similar locales.
   */
  const localeMap: { [key: string]: string } = {
    en_CA: 'en_US',
  };
  if (localeMap.hasOwnProperty(tempLocale)) {
    tempLocale = localeMap[tempLocale];
  }
  return tempLocale;
}
