import ProviderDataModel from '../ProviderDataModel';

export default class VivaWalletModel extends ProviderDataModel {
  redirectUrl: string;

  /**
   * @param {Object} data
   */
  constructor(data) {
    super();
    this.redirectUrl = data.redirectUrl;
  }
}
