import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmisysButton from '../EmisysButton/EmisysButton';
import Quantity from '../Quantity/Quantity';
import Price from '../Price/Price';
import { postBasketItemRequest } from '../../redux/actions/basket.actions';
import { dispatchAddToCart } from '../../services/tracking';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import './UpsellingItem.css';

class UpsellingItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingItem: false,
      quantity: 1,
    };
    this.newBasketItem = {
      formuleId: null,
      quantity: 1,
      sessionId: null,
      project: shopConfig.project.name,
      pos: shopConfig.pos.slug,
    };
  }

  componentDidMount() {
    this.newBasketItem.formuleId = this.props.upsellingItem.id;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentBasket !== this.props.currentBasket) {
      if (!this.props.currentBasket.loading) {
        this.setState({ loadingItem: false });
      }
    }
  }

  handleMin = () => {
    this.setState({ quantity: this.state.quantity - 1 }, () => {
      this.newBasketItem.quantity = this.state.quantity;
    });
  };

  handlePlus = () => {
    this.setState({ quantity: this.state.quantity + 1 }, () => {
      this.newBasketItem.quantity = this.state.quantity;
    });
  };

  handleAddUpselling = () => {
    const { upsellingItem } = this.props;
    this.props.postBasketItem(this.newBasketItem);
    this.setState({ loadingItem: true });
    dispatchAddToCart(upsellingItem.name, upsellingItem.price);
  };

  render() {
    const { quantity } = this.state;
    const { upsellingItem } = this.props;

    return (
      <div className="upsellingItemContainer">
        <div className="upsellingItemContainerName">
          <div className="upsellingItemName">{upsellingItem.name}</div>
        </div>
        <div className="upsellingItemContainerQtty">
          {!upsellingItem.isSoldOut && (
            <>
              <div className="upselling-item-info">
                <Price
                  currentPrice={upsellingItem.price}
                  currency={shopConfig.locale.currencyCode}
                />
              </div>
              <div className="upselling-item-info">
                <div>
                  <Quantity
                    minQuantity={1}
                    handleMin={this.handleMin}
                    currentValue={quantity}
                    handlePlus={this.handlePlus}
                  />
                </div>
              </div>
              <div className="upselling-item-info">
                <EmisysButton
                  loading={this.state.loadingItem}
                  disabled={upsellingItem.isSoldOut === 'soldout'}
                  onClick={this.handleAddUpselling}
                  isOutlined
                >
                  {upsellingItem.isSoldOut === 'soldout'
                    ? Translator.trans('product.soldout')
                    : Translator.trans('product.button.add')}
                </EmisysButton>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentBasket: state.basket.currentBasket,
});

const mapDispatchToProps = (dispatch) => ({
  postBasketItem: (newBasket) => dispatch(postBasketItemRequest(newBasket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpsellingItem);
