import AmountModel from './AmountModel';

export default class OrderPaymentModel {
  amount: AmountModel;
  confirmedAt: string;
  id: number;
  signature: string;
  totalItems: number;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.amount = new AmountModel(data.amount);
    this.confirmedAt = data.confirmedAt;
    this.totalItems = data.totalItems;
    this.signature = data.signature;
    this.id = data.id;
  }
}
