import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Collapse, Form, Spin } from 'antd';
import EmisysButton from '../../../components/EmisysButton/EmisysButton';
import Container from '../../../components/Container/Container';
import Title from '../../../components/Title/Title';
import { Type } from '../../../components/Form/FormType';
import { validateFormMessages } from '../../../services/validateFormMessage';
import {
  getAttendeeFormRequest,
  postAttendeeFormRequest,
} from '../../../redux/actions/attendee.actions';
import { checkReduxResponse } from '../../../services/httpService';
import { openNotificationError } from '../../../components/Notification/Notification';
import Translator from '../../../services/translator';
import '../index.css';

const { Panel } = Collapse;

class AttendeeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getLoading: false,
      postLoading: false,
      initialValues: null,
      openPanel: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.props.getAttendeeForm();

    if (this.props.attendeesInfo) {
      this.updateAttendeesForm(this.props.attendeesInfo);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.attendeeForm !== prevProps.attendeeForm) {
      this.setState({ getLoading: this.props.attendeeForm.loading });
      if (checkReduxResponse(this.props.attendeeForm, 'form')) {
        this.props.updateAttendeeForm(this.props.attendeeForm.form);
        this.initiateForm(this.props.attendeeForm.form);
      }

      if (this.props.attendeeForm.error) {
        openNotificationError('', this.props.attendeeForm.errorMessage);
      }
    }

    if (this.props.newAttendeeFrom !== prevProps.newAttendeeFrom) {
      this.setState({ postLoading: this.props.newAttendeeFrom.loading });
      if (checkReduxResponse(this.props.newAttendeeFrom, 'form')) {
        this.props.goToNextStep();
      }

      if (this.props.newAttendeeFrom.error) {
        if (this.props.newAttendeeFrom.error.message) {
          openNotificationError(
            Translator.trans('error.internal_error.title'),
            this.props.newAttendeeFrom.error.message
          );
        }

        if (this.props.newAttendeeFrom.error.formErrors) {
          this.handleDisplayErrors(this.props.newAttendeeFrom.error.formErrors);
        }
      }
    }
  }

  initiateForm = (formQuestions) => {
    let newAttendeeInfo = {};
    formQuestions.forEach((data) => {
      newAttendeeInfo[data.saleKey] = {};

      data.questions.forEach((question) => {
        let questionId = data.saleKey + '#' + question.id;
        if (
          question.titleLevel !== 'title' &&
          question.titleLevel !== 'subtitle'
        ) {
          newAttendeeInfo[data.saleKey][questionId] = question.value;
        }
      });
    });

    this.updateAttendeesForm(newAttendeeInfo);
    this.openAllCollapsePanels(formQuestions);
  };

  openAllCollapsePanels = (form) => {
    let newOpenPanels = [];

    form.forEach((data) => {
      newOpenPanels.push(data.saleKey);
    });

    this.setState({ openPanel: newOpenPanels });
  };

  handleOpenPanel = (openPanels) => {
    this.setState({ openPanel: openPanels });
  };

  handleAttendeeForm = (event, question, saleKey) => {
    const newAttendeeInfo = { ...this.props.attendeesInfo };
    newAttendeeInfo[saleKey][question] = event;

    this.updateAttendeesForm(newAttendeeInfo);
  };

  updateAttendeesForm = (form) => {
    let newObj = {};

    for (let key in form) {
      for (let subKey in form[key]) {
        newObj[subKey] = form[key][subKey];
      }
    }

    this.setState({ initialValues: newObj });
    this.props.updateAttendeesInfo(form);
  };

  handleDisplayErrors = (errors) => {
    let setErrors = [];

    errors.forEach((error) => {
      let matches = (error.fieldName.match(/\[.+?]/g) || []).map((str) =>
        str.slice(1, -1)
      );

      if (matches.length > 0) {
        setErrors.push({
          name: matches.join('#'),
          errors: [error.message],
        });
      }
    });

    if (setErrors.length > 0) {
      this.formRef.current?.setFields(setErrors);
      this.formRef.current?.scrollToField(setErrors[0].name);

      this.openAllCollapsePanels(this.props.form);
    }
  };

  handleRemoveErrors = (obj) => {
    let errors = [];

    Object.keys(obj).map((key) =>
      errors.push({
        name: key,
        errors: '',
      })
    );

    this.formRef.current?.setFields(errors);
  };

  onFinish = () => {
    const payload = this.removeHashtag(this.props.attendeesInfo);

    this.handleRemoveErrors(this.formRef.current?.getFieldsValue());

    this.props.postAttendeeForm(payload);
  };

  removeHashtag = (obj) => {
    const newObj = {};
    Object.entries(obj).forEach(([key, value]) => {
      newObj[key] = {};
      Object.entries(value).forEach(([key2, value2]) => {
        newObj[key][key2.split('#')[1]] = value2;
      });
    });
    return newObj;
  };

  render() {
    const { attendeesInfo, form } = this.props;
    const { getLoading, postLoading, initialValues, openPanel } = this.state;

    return (
      <Col className="gutter-row" xs={24}>
        <Spin spinning={getLoading}>
          {initialValues ? (
            <Form
              ref={this.formRef}
              name="attendeesForm"
              onFinish={this.onFinish}
              initialValues={initialValues}
              scrollToFirstError
              validateMessages={validateFormMessages}
            >
              <Collapse
                activeKey={openPanel}
                onChange={this.handleOpenPanel}
                ghost
              >
                {form &&
                  form.map((attendee, attendeeIndex) => (
                    <Panel
                      key={attendee.saleKey}
                      showArrow={false}
                      className="basket-attendee-container"
                      header={
                        <Title
                          value={Translator.trans(
                            'form.attendee.ticket_title',
                            {
                              nbr: attendeeIndex + 1,
                              title: attendee.saleName,
                            }
                          )}
                        />
                      }
                    >
                      {attendeesInfo &&
                        attendee.questions.map((question, questionIndex) => (
                          <Type
                            key={questionIndex}
                            uniqueId={attendee.saleKey + '#' + question.id}
                            saleKey={attendee.saleKey}
                            question={question}
                            value={
                              attendeesInfo[attendee.saleKey][
                                attendee.saleKey + '#' + question.id
                              ]
                            }
                            onChange={this.handleAttendeeForm}
                          />
                        ))}
                    </Panel>
                  ))}
              </Collapse>

              <Container className={'basket-attendee-container'}>
                <EmisysButton
                  className={'basket-attendee-next'}
                  loading={postLoading}
                  htmlType="submit"
                >
                  {Translator.trans('navbar.next')}
                </EmisysButton>
              </Container>
            </Form>
          ) : (
            <Container className={'empty-form-container'} />
          )}
        </Spin>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  attendeeForm: state.attendee.attendeeForm,
  newAttendeeFrom: state.attendee.newAttendeeFrom,
});

const mapDispatchToProps = (dispatch) => ({
  getAttendeeForm: () => dispatch(getAttendeeFormRequest()),
  postAttendeeForm: (form) => dispatch(postAttendeeFormRequest(form)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeForm);
