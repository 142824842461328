import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from '../../services/history';
import OrderConfirmModel, {
  paymentProviders,
} from '../../Models/OrderOverview/OrderConfirmModel';
import Payconiq from '../../components/Providers/Payconiq/Payconiq';
import Debug from '../../components/Providers/Debug/Debug';
import SquareUp from '../../components/Providers/SquareUp/SquareUp';
import VivaWalletModel from '../../Models/ProviderData/VivaWallet/VivawalletModel';
import PaybeModel from '../../Models/ProviderData/Paybe/PaybeModel';
import OrderModel from '../../Models/OrderModel';
import PaymentResultModel from '../../Models/PaymentResultModel';

interface ProviderProps {
  current: OrderConfirmModel;
  cancelProvider?: () => void;
}

export interface ProviderChildProps {
  isOpen: boolean;
  handleClose: () => void | undefined;
  provider: OrderConfirmModel | PaymentResultModel;
  currentBasket?: OrderModel;
  handleRedirect: (arg: string) => void;
}

const Provider = (props: ProviderProps) => {
  const currentBasket = useSelector((state: any) => state.basket.currentBasket);
  const searchParams =
    '?id=' + props.current.id + '&signature=' + props.current.signature;

  const handleReturnUrl = useCallback(
    (pathname) => {
      if (props.current.returnUrl) {
        window.location.href = props.current.returnUrl;
      } else {
        history.navigate(
          {
            pathname: pathname,
            search: searchParams,
          },
          {
            state: { fromPayment: true },
          }
        );
      }
    },
    [props, searchParams]
  );

  const handleRedirect = useCallback(
    (pathname) => {
      if (props.cancelProvider) {
        history.navigate(
          {
            pathname: pathname,
            search: searchParams,
          },
          {
            state: { fromPayment: true },
          }
        );
      } else {
        handleReturnUrl(pathname);
      }
    },
    [handleReturnUrl, props, searchParams]
  );

  useEffect(() => {
    const providerData = props.current.providerData;
    if (
      providerData !== null &&
      (providerData instanceof VivaWalletModel ||
        providerData instanceof PaybeModel)
    ) {
      window.location.replace(providerData.redirectUrl);
    } else {
      if (
        props.current.provider === paymentProviders.NONE &&
        props.current.status === 'complete'
      ) {
        handleRedirect('/payment/success');
      }
    }
  }, [handleRedirect, props]);

  return (
    <div>
      {props.current.provider === paymentProviders.PAYCONIQ && (
        <Payconiq
          isOpen={props.current.provider === paymentProviders.PAYCONIQ}
          handleClose={props.cancelProvider}
          provider={props.current}
          handleRedirect={handleRedirect}
        />
      )}

      {props.current.provider === paymentProviders.DEBUG && (
        <Debug
          isOpen={props.current.provider === paymentProviders.DEBUG}
          handleClose={props.cancelProvider}
          provider={props.current}
          currentBasket={currentBasket?.basket}
          handleRedirect={handleRedirect}
        />
      )}

      {props.current.provider === paymentProviders.SQUAREUP && (
        <SquareUp
          isOpen={props.current.provider === paymentProviders.SQUAREUP}
          handleClose={props.cancelProvider}
          provider={props.current}
          handleRedirect={handleRedirect}
        />
      )}
    </div>
  );
};

export default Provider;
