import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { customerConstants } from '../constants/customer.constants';
import { get, getUrl, post } from '../../services/httpService';
import {
  getCustomerFormFailure,
  getCustomerFormSuccess,
  postCustomerFormFailure,
  postCustomerFormSuccess,
} from '../actions/customer.actions';
import { shopConfig } from '../../Globals/ShopConfig';
import getBasketSession from '../../services/basketSession';

export async function getCustomerFormFetch(arg) {
  const url = shopConfig.router.userCustomerFormUrl;
  return await get(getUrl(url, arg.basketSession));
}

export async function postCustomerFormFetch(arg) {
  const url = shopConfig.router.userCustomerFormSaveUrl;
  return await post(getUrl(url, arg.basketSession), arg.form);
}

export function* fetchGetCustomerForm() {
  try {
    const form = yield call(getCustomerFormFetch, {
      basketSession: getBasketSession(),
    });
    yield put(getCustomerFormSuccess(form));
  } catch (error) {
    yield put(getCustomerFormFailure(error));
  }
}

export function* fetchPostCustomerForm(action) {
  try {
    const form = yield call(postCustomerFormFetch, {
      form: action.form,
      basketSession: getBasketSession(),
    });
    yield put(postCustomerFormSuccess(form));
  } catch (error) {
    yield put(postCustomerFormFailure(error));
  }
}

export function* getCustomerForm() {
  yield takeLatest(
    customerConstants.GET_CUSTOMER_FORM_REQUEST,
    fetchGetCustomerForm
  );
}

export function* postCustomerForm() {
  yield takeLatest(
    customerConstants.POST_CUSTOMER_FORM_REQUEST,
    fetchPostCustomerForm
  );
}

export default function* customerSaga() {
  yield all([fork(getCustomerForm), fork(postCustomerForm)]);
}
