import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
import { createLogger } from 'redux-logger';
import { isTokenNearlyInvalid, isTokenOver } from '../../services/parseJwt';
import {
  addTokenToStorage,
  getRefreshTokenStorage,
  getTokenStorage,
  removeTokenToStorage,
} from '../../services/handleTokens';
import { post, getUrl } from '../../services/httpService';
import history from '../../services/history';
const loggerMiddleware = createLogger();

let refreshTokenRequest = 0;
let pendingRequest = [];

const refreshTokenMiddleware = () => (next) => (action) => {
  const token = getTokenStorage();
  const refreshToken = getRefreshTokenStorage();

  if (token && refreshToken) {
    if (isTokenOver(token)) {
      pendingRequest.push({
        next: next,
        action: action,
      });
      if (!refreshTokenRequest) {
        refreshCurrentToken();
      }
    } else {
      if (isTokenNearlyInvalid(token)) {
        if (!refreshTokenRequest) {
          refreshCurrentToken();
        }
      }

      return next(action);
    }
  } else {
    return next(action);
  }
};

const refreshCurrentToken = () => {
  const token = getTokenStorage();
  const refreshToken = getRefreshTokenStorage();

  refreshTokenRequest++;

  post(getUrl('/api/refresh_token'), { refresh_token: refreshToken })
    .then((response) => {
      addTokenToStorage(response);

      refreshTokenRequest = 0;
      pendingRequest.map((request) => request.next(request.action));
      pendingRequest = [];
    })
    .catch(() => {
      if (isTokenNearlyInvalid(token)) {
        if (refreshTokenRequest < 3) {
          setTimeout(() => {
            refreshCurrentToken();
          }, 10000);
        } else {
          refreshTokenRequest = 0;
        }
      } else {
        if (isTokenOver(token)) {
          pendingRequest = [];
          history.navigate('/');
          removeTokenToStorage();
        }
      }
    });
};

const sagaMiddleware = createSagaMiddleware();
const middlewares: any = [refreshTokenMiddleware, sagaMiddleware];

if (process.env.REACT_APP_ENABLE_LOG === 'true') {
  middlewares.push(loggerMiddleware);
}

export default function getStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
  });

  sagaMiddleware.run(rootSaga);
  return store;
}
