export default class FormuleModel {
  id: number;
  name: string;
  price: number;
  quantityIncrement: null | number;
  quantityMaxPerSale: null | number;
  quantityMinPerSale: null | number;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.price = data.price;
    this.quantityIncrement = data.quantityIncrement;
    this.quantityMaxPerSale = data.quantityMaxPerSale;
    this.quantityMinPerSale = data.quantityMinPerSale;
  }
}
