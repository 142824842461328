import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { optionsConstants } from '../constants/options.constants';
import {
  getOptionsSuccess,
  getOptionsFailure,
  getSessionOptionsSuccess,
  getSessionOptionsFailure,
} from '../actions/options.actions';
import { get } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getOptionsFetch(arg) {
  const url = shopConfig.router.productOptionListUrl;
  return await get(url + '?id=' + arg.options);
}

export async function getSessionOptionsFetch(arg) {
  const url = shopConfig.router.productOptionSessionListUrl;
  return await get(
    url + '?product_id=' + arg.optionId + '&session_id=' + arg.sessionId
  );
}

export function* fetchGetOptions(action) {
  try {
    const options = yield call(getOptionsFetch, {
      options: action.options,
    });
    yield put(getOptionsSuccess(options));
  } catch (error) {
    yield put(getOptionsFailure(error));
  }
}

export function* fetchGetSessionOptions(action) {
  try {
    const options = yield call(getSessionOptionsFetch, {
      optionId: action.product_id,
      sessionId: action.session_id,
    });
    yield put(getSessionOptionsSuccess(options));
  } catch (error) {
    yield put(getSessionOptionsFailure(error));
  }
}

export function* getOptions() {
  yield takeLatest(optionsConstants.GET_OPTIONS_REQUEST, fetchGetOptions);
}

export function* getSessionOptions() {
  yield takeLatest(
    optionsConstants.GET_SESSION_OPTIONS_REQUEST,
    fetchGetSessionOptions
  );
}

export default function* optionsSaga() {
  yield all([fork(getOptions), fork(getSessionOptions)]);
}
