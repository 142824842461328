import React, { Component } from 'react';
import Calendar from 'react-calendar';
import Container from '../Container/Container';
import SessionSchedule from '../Sessions/SessionSchedule';
import { formatToFilterLocaleDate } from '../../services/formatDate';
import { getFilterDateStorage } from '../../services/handleStorage';
import { shopConfig } from '../../Globals/ShopConfig';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';

class DrawerCalendarSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      currentSchedule: null,
      enabledDates: [],
      locale: shopConfig.locale.locale,
    };
    this.filterDate = getFilterDateStorage();
  }

  componentDidMount() {
    this.setEnabledDates(this.props.filterDate);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterDate !== prevProps.filterDate) {
      this.setEnabledDates(this.props.filterDate);
    }
  }

  setEnabledDates = (filterDate) => {
    if (filterDate && Object.keys(filterDate).length > 0) {
      this.setState({ enabledDates: Object.keys(filterDate) }, () => {
        const activeFirstDate = this.filterDate
          ? this.filterDate
          : new Date(this.state.enabledDates[0] + 'T00:00');

        this.setState({ currentDate: activeFirstDate });
        this.onClickDay(activeFirstDate);
      });
    }
  };

  onClickDay = (date) => {
    const keyDate = formatToFilterLocaleDate(date);
    const currentSchedule = this.props.filterDate[keyDate];
    this.setState({ currentSchedule: currentSchedule, currentDate: date });
    this.props.onClickDay(keyDate);
  };

  disableCalenderTile = ({ date }) => {
    const today = new Date();
    const yesterday = new Date(today.getTime());
    yesterday.setDate(today.getDate() - 1);
    const keyDate = formatToFilterLocaleDate(date);

    return !this.state.enabledDates.includes(keyDate) || date < yesterday;
  };

  render() {
    const { locale, currentDate, currentSchedule } = this.state;
    const { showCalender, showSchedule, onClickSchedule, showArrow } =
      this.props;

    return (
      <div className="drawer-calendar-container">
        {showCalender && (
          <Calendar
            className="drawer-calendar"
            tileClassName="drawer-calendar-tile"
            minDetail="month"
            locale={locale}
            tileDisabled={this.disableCalenderTile}
            onClickDay={this.onClickDay}
            value={currentDate}
          />
        )}
        <Container
          className={`drawer-calendar-schedule ${!showSchedule && 'hidden'} ${
            !showCalender && 'hiddenCalendar'
          }`}
        >
          {currentSchedule && (
            <SessionSchedule
              currentSchedule={currentSchedule}
              currentDate={currentDate}
              onClickSchedule={onClickSchedule}
              showArrow={(show) => showArrow(show)}
            />
          )}
        </Container>
      </div>
    );
  }
}

export default DrawerCalendarSession;
