export default class taxDetailModel {
  name: string;
  amount: number;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.name = data.name;
    this.amount = data.amount;
  }

  static separateObject(obj: { [key: string]: number }) {
    const res = [];
    const keys = Object.keys(obj);
    if (keys) {
      keys.forEach((key) => {
        res.push(
          new taxDetailModel({
            name: key,
            amount: obj[key],
          })
        );
      });
    }
    return res;
  }
}
