import { upsellingConstants } from '../constants/upselling.constants';

const initialState = {
  currentUpselling: '',
};

const upselling = (state = initialState, action: any) => {
  switch (action.type) {
    case upsellingConstants.GET_UPSELLING_REQUEST: {
      return {
        ...state,
        currentUpselling: { upselling: [], error: false, loading: true },
      };
    }

    case upsellingConstants.GET_UPSELLING_SUCCESS: {
      return {
        ...state,
        currentUpselling: {
          upselling: action.upselling,
          error: false,
          loading: false,
        },
      };
    }

    case upsellingConstants.GET_UPSELLING_FAILURE: {
      return {
        ...state,
        currentUpselling: { upselling: null, error: true, loading: false },
      };
    }
    default:
      return state;
  }
};

export default upselling;
