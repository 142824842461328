import ProviderDataModel from '../ProviderDataModel';

export default class SquareUpModel extends ProviderDataModel {
  applicationId: string;
  locationId: string;

  /**
   * @param {Object} data
   */
  constructor(data) {
    super();
    this.applicationId = data.applicationId;
    this.locationId = data.locationId;
  }
}
