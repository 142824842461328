import React, { Component } from 'react';
import { connect } from 'react-redux';
import Title from '../Title/Title';
import EmisysButton from '../EmisysButton/EmisysButton';
import { postBasketDiscountRequest } from '../../redux/actions/basket.actions';
import { Spin, Skeleton } from 'antd';
import Container from '../Container/Container';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import { checkReduxResponse } from '../../services/httpService';
import AddPromotion from '../AddPromotion/AddPromotion';
import BasketPriceDetail from '../BasketPriceDetail/BasketPriceDetail';
import Translator from '../../services/translator';

class BasketSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingItems: false,
      basketItems: [],
    };
  }

  componentDidMount() {
    if (this.props.currentBasket !== null) {
      this.setBasketItems(this.props.currentBasket);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentBasket !== this.props.currentBasket) {
      this.setState({ loadingItems: this.props.currentBasket.loading });
      if (checkReduxResponse(this.props.currentBasket, 'basket')) {
        this.setBasketItems(this.props.currentBasket);
        this.props.updateBasketReview();
        this.setState({ loading: false });
      }
    }
  }

  setBasketItems = (currentBasket) => {
    if (currentBasket.basket) {
      this.setState({
        basketItems: currentBasket.basket.items
          ? currentBasket.basket.items
          : [],
        subTotal: currentBasket.basket.subTotal,
        feeAmount: currentBasket.basket.feeAmount,
        totalPriceWithoutVat: currentBasket.basket.totalPriceWithoutVat,
        taxDetails: currentBasket.basket.taxDetails,
        totalPriceWithVat: currentBasket.basket.totalPriceWithVat,
      });
    }
  };

  addPromoCode = (value) => {
    let promoCode = {};
    promoCode.code = value;
    this.props.postDiscount(promoCode);
  };

  handleConfirmPage = () => this.props.goToNextStep();

  render() {
    const { loading, loadingItems, basketItems } = this.state;

    return (
      <Container>
        <Title value={Translator.trans('accounting.detail')} />
        <Skeleton active loading={loading}>
          <Spin spinning={loadingItems}>
            {!loading && (
              <div>
                <AddPromotion onConfirm={this.addPromoCode} />

                <BasketPriceDetail
                  subTotal={this.state.subTotal}
                  feeAmount={this.state.feeAmount}
                  totalPriceWithoutVat={this.state.totalPriceWithoutVat}
                  totalPriceWithVat={this.state.totalPriceWithVat}
                  taxDetails={this.state.taxDetails}
                />

                <EmisysButton
                  disabled={basketItems?.length === 0}
                  onClick={this.handleConfirmPage}
                >
                  {Translator.trans('order.button.confirm')}
                </EmisysButton>

                <PaymentMethod />
              </div>
            )}
          </Spin>
        </Skeleton>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ticketsList: state.tickets.ticketsList,
  currentBasket: state.basket.currentBasket,
});

const mapDispatchToProps = (dispatch) => ({
  postDiscount: (discount) => dispatch(postBasketDiscountRequest(discount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasketSummary);
