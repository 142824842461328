export default class PdfFileModel {
  url: string;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.url = data.url;
  }
}
