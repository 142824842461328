import CategoryModel from './CategoryModel';

export default class CategoriesModel {
  categories: CategoryModel[] | undefined;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.categories = data.categories?.map(
      (category) => new CategoryModel(category)
    );
  }
}
