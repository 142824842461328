import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUpsellingRequest } from '../../redux/actions/upselling.actions';
import { Skeleton } from 'antd';
import Title from '../Title/Title';
import Container from '../Container/Container';
import UpsellingItem from './UpsellingItem';
import Translator from '../../services/translator';
import { checkReduxResponse } from '../../services/httpService';
import './Upselling.css';

class Upselling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      upsellingItems: [],
    };
  }

  componentDidMount() {
    this.props.getUpselling();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUpselling !== this.props.currentUpselling) {
      this.setState({ loading: this.props.currentUpselling.loading });
      if (checkReduxResponse(this.props.currentUpselling, 'upselling')) {
        this.setState({
          upsellingItems: this.props.currentUpselling.upselling,
        });
      }
    }
  }

  render() {
    const { loading, upsellingItems } = this.state;
    return (
      Array.isArray(upsellingItems) &&
      upsellingItems.length !== 0 && (
        <Container className={'upselling-container'}>
          <Title value={Translator.trans('upselling.title')} />
          <Skeleton active loading={loading}>
            <div>
              <div className="upselling-items-container">
                {upsellingItems.map((data, i) => (
                  <UpsellingItem key={i} upsellingItem={data} />
                ))}
              </div>
            </div>
          </Skeleton>
        </Container>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  currentUpselling: state.upselling.currentUpselling,
});

const mapDispatchToProps = (dispatch) => ({
  getUpselling: () => dispatch(getUpsellingRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upselling);
