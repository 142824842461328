import React from 'react';
import {
  BirthdayType,
  CheckBoxType,
  ChoiceType,
  CountryType,
  EmailType,
  FileType,
  GenderType,
  IntegerType,
  LanguageType,
  PhoneNumberType,
  TextAreaType,
  TextType,
  UrlType,
  UserPictureType,
} from './FormTypesPreview/FormTypesPreview';
import { formTypeConstant } from '../../Constants/Constants';
import './Form.css';

export const Type = (props) => {
  if (props.question.title) {
    switch (props.question.titleLevel) {
      case 'title':
        return <h4 className={'dynFormTitle'}>{props.question.label}</h4>;
      case 'subtitle':
        return <h5 className={'dynFormSubTitle'}>{props.question.label}</h5>;
      default:
        return '';
    }
  } else {
    switch (props.question.type) {
      case formTypeConstant.TextType:
        return <TextType {...props} />;
      case formTypeConstant.UrlType:
        return <UrlType {...props} />;
      case formTypeConstant.BirthdayType:
        return <BirthdayType {...props} />;
      case formTypeConstant.PhoneNumberType:
        return <PhoneNumberType {...props} />;
      case formTypeConstant.GenderType:
        return <GenderType {...props} />;
      case formTypeConstant.CountryType:
        return <CountryType {...props} />;
      case formTypeConstant.CheckboxType:
        return <CheckBoxType {...props} />;
      case formTypeConstant.ChoiceType:
        return <ChoiceType {...props} />;
      case formTypeConstant.EmailType:
        return <EmailType {...props} />;
      case formTypeConstant.DynFormFileType:
        return <FileType {...props} />;
      case formTypeConstant.IntegerType:
        return <IntegerType {...props} />;
      case formTypeConstant.TextareaType:
        return <TextAreaType {...props} />;
      case formTypeConstant.UserPictureType:
        return <UserPictureType {...props} />;
      case formTypeConstant.UserNationalityCollectionType:
        return <CountryType {...props} multiple />;
      case formTypeConstant.LanguageType:
        return <LanguageType {...props} />;
      case formTypeConstant.UserLanguageCollectionType:
        return <LanguageType {...props} multiple />;
      default:
        throw new Error('Forgotten type :' + props.question.type);
    }
  }
};
