export default class AddressModel {
  city: string | null;
  country: string | null;
  streetLine: string | null;
  zip: string | null;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.city = data.city;
    this.country = data.country;
    this.streetLine = data.streetLine;
    this.zip = data.zip;
  }
}
