export const orderConstants = {
  GET_ORDER_OVERVIEW_REQUEST: 'GET_ORDER_OVERVIEW_REQUEST',
  GET_ORDER_OVERVIEW_SUCCESS: 'GET_ORDER_OVERVIEW_SUCCESS',
  GET_ORDER_OVERVIEW_FAILURE: 'GET_ORDER_OVERVIEW_FAILURE',

  POST_ORDER_CONFIRM_REQUEST: 'POST_ORDER_CONFIRM_REQUEST',
  POST_ORDER_CONFIRM_SUCCESS: 'POST_ORDER_CONFIRM_SUCCESS',
  POST_ORDER_CONFIRM_FAILURE: 'POST_ORDER_CONFIRM_FAILURE',

  GET_PENDING_ORDER_REQUEST: 'GET_PENDING_ORDER_REQUEST',
  GET_PENDING_ORDER_SUCCESS: 'GET_PENDING_ORDER_SUCCESS',
  GET_PENDING_ORDER_FAILURE: 'GET_PENDING_ORDER_FAILURE',
};
