import FormuleModel from './FormuleModel';

export default class OptionModel {
  id: number;
  name: string;
  formule: FormuleModel | null;
  description: string;
  qtyRemaining: number | null;
  quotaMax: number | null;
  quantity: number;
  totalPrice: number;
  sessionId: number;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.formule = data?.formule ? new FormuleModel(data.formule) : null;
    this.description = data.description;
    this.qtyRemaining = data.qtyRemaining;
    this.quotaMax = data.quotaMax;
    this.quantity = data.quantity ? data.quantity : 0;
    this.totalPrice = getTotalPrice(data);
    this.sessionId = data?.sessionId ? data.sessionId : null;
  }
}

function getTotalPrice(data): number {
  return data.quantity ? data.formule.price * data.quantity : 0;
}
