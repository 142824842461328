import { all } from 'redux-saga/effects';
import ticketsSaga from './tickets.saga';
import optionsSaga from './options.saga';
import basketSaga from './basket.saga';
import sessionsSaga from './sessions.saga';
import datesSaga from './dates.saga';
import paymentMethodSaga from './paymentmethod.saga';
import upsellingSaga from './upselling.saga';
import accommodationSaga from './accommodation.saga';
import sectionsSaga from './sections.saga';
import cmsSaga from './cms.saga';
import loginSaga from './login.saga';
import customerSaga from './customer.saga';
import orderSaga from './order.saga';
import users from './users.saga';
import paymentSaga from './payment.saga';
import attendeeSaga from './attendee.saga';

export default function* rootSaga() {
  yield all([
    ticketsSaga(),
    optionsSaga(),
    basketSaga(),
    sessionsSaga(),
    datesSaga(),
    paymentMethodSaga(),
    upsellingSaga(),
    accommodationSaga(),
    sectionsSaga(),
    cmsSaga(),
    loginSaga(),
    customerSaga(),
    orderSaga(),
    users(),
    paymentSaga(),
    attendeeSaga(),
  ]);
}
