import { basketConstants } from '../constants/basket.constants';
import {
  removeBasketCookie,
  setBasketCookie,
} from '../../services/handleCookies';
import OrderModel from '../../Models/OrderModel';
import { BasketStatusConstant } from '../../Constants/Constants';
import { openNotificationWarning } from '../../components/Notification/Notification';
import Translator from '../../services/translator';
import history from '../../services/history';

const initialState = {
  currentBasket: null,
};

let basketCurrent = {};

const basket = (state = initialState, action: any) => {
  switch (action.type) {
    case basketConstants.GET_BASKET_REQUEST: {
      return {
        ...state,
        currentBasket: { basket: basketCurrent, error: false, loading: true },
      };
    }

    case basketConstants.GET_BASKET_SUCCESS: {
      if (action.basket?.status === BasketStatusConstant.expired) {
        removeBasketCookie();
        history.navigate('/tickets');

        openNotificationWarning(
          Translator.trans('error.expired.basket.title'),
          Translator.trans('error.expired.basket.info')
        );

        basketCurrent = {};
      } else {
        basketCurrent = action.basket ? new OrderModel(action.basket) : {};
      }

      return {
        ...state,
        currentBasket: { basket: basketCurrent, error: false, loading: false },
      };
    }

    case basketConstants.GET_BASKET_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error.body?.message,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_ITEM_REQUEST: {
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: true,
        },
      };
    }

    case basketConstants.POST_BASKET_ITEM_SUCCESS: {
      if (action.newItem.signature && action.newItem.id) {
        setBasketCookie(action.newItem.signature, action.newItem.id);
      }

      basketCurrent = new OrderModel(action.newItem.data.basket);

      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_ITEM_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error?.body?.message,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_MULTIPLE_ITEM_REQUEST: {
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: true,
        },
      };
    }

    case basketConstants.POST_BASKET_MULTIPLE_ITEM_SUCCESS: {
      if (action.newItem.signature && action.newItem.id) {
        setBasketCookie(action.newItem.signature, action.newItem.id);
      }

      basketCurrent = new OrderModel(action.newItem.data.basket);

      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_MULTIPLE_ITEM_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error?.body?.message,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_ACCOMMODATION_REQUEST: {
      return {
        ...state,
        currentBasket: { basket: basketCurrent, error: false, loading: true },
      };
    }

    case basketConstants.POST_BASKET_ACCOMMODATION_SUCCESS: {
      basketCurrent = new OrderModel(action.newAccommodation.data);
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_ACCOMMODATION_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error.body?.message,
          loading: false,
        },
      };
    }

    case basketConstants.PUT_BASKET_ITEM_REQUEST: {
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: true,
        },
      };
    }

    case basketConstants.PUT_BASKET_ITEM_SUCCESS: {
      basketCurrent = new OrderModel(action.updateItem.data.basket);
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.PUT_BASKET_ITEM_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error.body?.message,
          loading: false,
        },
      };
    }

    case basketConstants.DELETE_ALL_BASKET_REQUEST: {
      return {
        ...state,
        currentBasket: { basket: basketCurrent, error: false, loading: true },
      };
    }

    case basketConstants.DELETE_ALL_BASKET_SUCCESS: {
      basketCurrent = {};
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.DELETE_ALL_BASKET_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          error: action.error.body?.message,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_DISCOUNT_REQUEST: {
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          isValid: false,
          error: false,
          loading: true,
        },
      };
    }

    case basketConstants.POST_BASKET_DISCOUNT_SUCCESS: {
      basketCurrent = new OrderModel(action.newBasketWithDiscount);
      return {
        ...state,
        currentBasket: {
          basket: basketCurrent,
          isValid: true,
          error: false,
          loading: false,
        },
      };
    }

    case basketConstants.POST_BASKET_DISCOUNT_FAILURE: {
      return {
        ...state,
        currentBasket: {
          basket: {},
          isValid: false,
          error: action.error.body?.error,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default basket;
