import { orderConstants } from '../constants/order.constants';

export const getOrderOverviewRequest = () => ({
  type: orderConstants.GET_ORDER_OVERVIEW_REQUEST,
});

export const getOrderOverviewSuccess = (order: object) => ({
  type: orderConstants.GET_ORDER_OVERVIEW_SUCCESS,
  order,
});

export const getOrderOverviewFailure = (error: object) => ({
  type: orderConstants.GET_ORDER_OVERVIEW_FAILURE,
  error,
});

export const postOrderConfirmRequest = (order: object) => ({
  type: orderConstants.POST_ORDER_CONFIRM_REQUEST,
  order,
});

export const postOrderConfirmSuccess = (response: object) => ({
  type: orderConstants.POST_ORDER_CONFIRM_SUCCESS,
  response,
});

export const postOrderConfirmFailure = (error: object) => ({
  type: orderConstants.POST_ORDER_CONFIRM_FAILURE,
  error,
});

export const getPendingOrderRequest = () => ({
  type: orderConstants.GET_PENDING_ORDER_REQUEST,
});

export const getPendingOrderSuccess = (response: object) => ({
  type: orderConstants.GET_PENDING_ORDER_SUCCESS,
  response,
});

export const getPendingOrderFailure = (error: object) => ({
  type: orderConstants.GET_PENDING_ORDER_FAILURE,
  error,
});
