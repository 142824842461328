import OptionRenderingModel from './OptionRenderingModel';
import FormuleModel from './FormuleModel';

export default class OptionRenderingValuesModel {
  id: number;
  name: string;
  description: null | string;
  qtyRemaining: null | number;
  qtySold: null | number;
  quotaMax: null | number;
  child: OptionRenderingModel | null;
  formule: FormuleModel;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.qtyRemaining = data.qtyRemaining;
    this.qtySold = data.qtySold;
    this.quotaMax = data.quotaMax;
    this.child = data.child ? new OptionRenderingModel(data.child) : null;
    this.formule = data?.formule ? new FormuleModel(data.formule) : null;
  }
}
