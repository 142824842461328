export const currencyPrecision = 0.01;

export const CONSTANTSCMS = {
  TERMSURL: 'termsUrl',
  LEGALURL: 'legalUrl',
  POLICYURL: 'policyUrl',
  INFOSURL: 'infoUrl',
};

export const basketTabs = {
  VALIDATION: 'validation',
  CUSTOMER: 'customer-info',
  ATTENDEE: 'attendee-info',
  PAYMENT: 'payment',
};

export const shopConstants = {
  SIGNIN: 'signIn',
  SIGNUP: 'signUp',
  FORGOTPASSWORD: 'forgotten-password',
  RESETPASSWORD: 'reset-password',
};

export const sessionFilters = {
  languages: 'languages',
  performers: 'performers',
  places: 'places',
};

export const formTypeConstant = {
  TextType: 'TextType',
  UrlType: 'UrlType',
  BirthdayType: 'BirthdayType',
  PhoneNumberType: 'PhoneNumberType',
  GenderType: 'GenderType',
  CountryType: 'CountryType',
  CheckboxType: 'CheckboxType',
  ChoiceType: 'ChoiceType',
  EmailType: 'EmailType',
  DynFormFileType: 'DynFormFileType',
  IntegerType: 'IntegerType',
  TextareaType: 'TextareaType',
  UserPictureType: 'UserPictureType',
  UserNationalityCollectionType: 'UserNationalityCollectionType',
  LanguageType: 'LanguageType',
  UserLanguageCollectionType: 'UserLanguageCollectionType',
};

export const BasketStatusConstant = {
  payment_accept: 'payment_accept',
  cancel: 'cancel',
  expired: 'expired',
  draft: 'draft',
  awaiting_payment_online: 'awaiting_payment_online',
};

export const PaymentStatusConstant = {
  PAID: 'paid',
  CANCEL: 'cancel',
};

export const ProductStatusConstant = {
  soldout: 'soldout',
  comingsoon: 'comingsoon',
  expired: 'expired',
  onsale: 'onsale',
};

export const ProductTypeConstant = {
  ticket: 'ticket',
  pack: 'pack',
  topup: 'topup',
  accommodation: 'accomodation',
  transportation: 'transportation',
  drink: 'drink',
  food: 'food',
  extra: 'extra',
  shop: 'shop',
  discount: 'discount',
};
