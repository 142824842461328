export default class ProviderNoneModel {
  type: string | null;
  bankTransferText: string | null;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.type = data.type;
    this.bankTransferText = data.bankTransferText ?? null;
  }
}
