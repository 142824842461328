export default class ShopBrandingModel {
  colorPrimary: string;
  colorSecondary: string;
  colorBackground: string;
  backgroundPictureUrl: string;
  bannerPictureUrl: string;
  logoPictureUrl: string;
  accommodationSiteMap: string | null;
  customCss: string | null;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.colorPrimary = data.colorPrimary;
    this.colorSecondary = data.colorSecondary;
    this.colorBackground = data.colorBackground;
    this.backgroundPictureUrl = data.backgroundPictureUrl;
    this.bannerPictureUrl = data.bannerPictureUrl;
    this.logoPictureUrl = data.logoPictureUrl;
    this.accommodationSiteMap = null;
    this.customCss = data.customCss;
  }
}
