import AmountModel from './AmountModel';
import UserModel from './UserModel';
import OrderPaymentModel from './OrderPaymentModel';
import { paymentProviders } from './OrderOverview/OrderConfirmModel';
import ProviderDataModel from './ProviderData/ProviderDataModel';
import { createProviderData } from './ProviderData/ProviderDataCreator';
import AddressModel from './AddressModel';
import PdfFileModel from './PdfFileModel';

export default class PaymentResultModel {
  amount: AmountModel;
  customer: UserModel | null;
  environment: string;
  errorMessage: string | null;
  id: number;
  locale: string;
  order: OrderPaymentModel;
  orderId: string;
  provider: keyof typeof paymentProviders;
  billingAddress: AddressModel | null;
  providerData: ProviderDataModel | null;
  returnUrl: string;
  signature: string;
  statementReference: string;
  pdfFiles: PdfFileModel[];
  status:
    | 'complete'
    | 'verification'
    | 'notStarted'
    | 'waitPayment'
    | 'technicalProblem'
    | 'canceled';

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.amount = new AmountModel(data.amount);
    this.customer = data.customer ? new UserModel(data.customer) : null;
    this.environment = data.environment;
    this.errorMessage = data.errorMessage;
    this.id = data.id;
    this.locale = data.locale;
    this.order = new OrderPaymentModel(data.order);
    this.orderId = data.orderId.toString();
    this.provider = data.provider;
    this.providerData = data.providerData
      ? createProviderData(data.provider, data.providerData)
      : null;
    this.returnUrl = data.returnUrl;
    this.signature = data.signature;
    this.statementReference = data.statementReference;
    this.status = data.status;
    this.pdfFiles = data.pdfFiles?.map((file) => new PdfFileModel(file));
    this.billingAddress = null;
  }
}
