import { shopConstants } from '../Constants/Constants';
import { addPreviousPathStorage, getShopTokenStorage } from './handleStorage';
import { getTokenStorage, removeTokenToStorage } from './handleTokens';
import history from './history';

type MethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type RequestInit = globalThis.RequestInit;

const getHeaders = (
  method: MethodType,
  body?: unknown,
  options?: any
): RequestInit => {
  const token = getTokenStorage();
  const header: RequestInit = {
    method: method,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : null,
  };

  if (token) {
    header.headers['Authorization'] = 'Bearer ' + token;
  } else {
    delete header.headers['Authorization'];
  }
  const privateShopToken = getShopTokenStorage();
  if (privateShopToken) {
    header.headers['X-emisys-privateShopToken'] = privateShopToken;
  }

  if (options && options.hasOwnProperty('signal')) {
    header['signal'] = options.signal;
  }
  return header;
};

export const get = (url: string | URL, options?: unknown) => {
  return fetch(url, getHeaders('GET', null, options))
    .then(checkStatus)
    .catch(handleError);
};

export const post = (url: string | URL, body?: unknown, options?: unknown) => {
  return fetch(url, getHeaders('POST', body, options))
    .then(checkStatus)
    .catch(handleError);
};

export const update = (url: string | URL, body?: unknown) => {
  return fetch(url, getHeaders('PUT', body))
    .then(checkStatus)
    .catch(handleError);
};

export const patch = (url: string | URL, body?: unknown) => {
  return fetch(url, getHeaders('PATCH', body))
    .then(checkStatus)
    .catch(handleError);
};

export const del = (url: string | URL) => {
  return fetch(url, getHeaders('DELETE')).then(checkStatus).catch(handleError);
};

export const getUrl = (path: string, args = {}) => {
  let newPath = new URL(path, process.env.REACT_APP_URL_BACKEND);

  for (let key in args) {
    if (args.hasOwnProperty(key)) {
      if (
        typeof args[key] === 'object' &&
        args[key] &&
        !Array.isArray(args[key])
      ) {
        newPath.searchParams.append(
          key + '|' + args[key].operation,
          args[key].value
        );
      } else if (Array.isArray(args[key])) {
        args[key].forEach((data) => {
          newPath.searchParams.append(key + '|' + data.operation, data.value);
        });
      } else if (args[key] || args[key] === 0) {
        newPath.searchParams.append(key, args[key]);
      }
    }
  }

  return newPath;
};

const checkStatus = (response: any) => {
  if (response.status === 401) {
    addPreviousPathStorage();
    removeTokenToStorage();
    history.navigate('/auth/' + shopConstants.SIGNIN);
  }

  if (response.status >= 200 && response.status < 300) {
    if (response.status === 204 || response.status === 205) {
      return null;
    }
    return response.json();
  }

  return response.json().then((json) =>
    Promise.reject({
      status: response.status,
      ok: false,
      statusText: response.statusText,
      body: json,
    })
  );
};

const handleError = (error: any) => {
  error.response = {
    status: 0,
    statusText:
      'Cannot connect. Please make sure you are connected to internet.',
  };
  throw error;
};

interface ReduxPropsType {
  [key: string]: any;
  loading: boolean;
  error: boolean;
}
export const checkReduxResponse = (props: ReduxPropsType, attr: string) => {
  return props && props[attr] && !props.loading && !props.error;
};
