import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPaymentMethodRequest } from '../../redux/actions/paymentMethod.actions';
import { checkReduxResponse } from '../../services/httpService';
import Translator from '../../services/translator';
import './PaymentMethod.css';

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethodList: null,
    };
  }

  componentDidMount() {
    if (this.props.paymentMethodList?.paymentMethod) {
      this.setState({
        paymentMethodList: this.props.paymentMethodList.paymentMethod,
      });
    } else {
      this.props.getPaymentMethod();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paymentMethodList !== this.props.paymentMethodList) {
      if (checkReduxResponse(this.props.paymentMethodList, 'paymentMethod')) {
        this.setState({
          paymentMethodList: this.props.paymentMethodList.paymentMethod,
        });
      }
    }
  }

  render() {
    const { paymentMethodList } = this.state;
    return (
      <div className="paymentMethod-container">
        {paymentMethodList && paymentMethodList.length !== 0 && (
          <>
            <div className="paymentMethod-title">
              {Translator.trans('payment.available_payment_methods')}
            </div>
            <div className="paymentMethod">
              {paymentMethodList.map((item, i) => (
                <img key={i} src={item.picture} alt={item.name} />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paymentMethodList: state.paymentMethod.paymentMethodList,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentMethod: () => dispatch(getPaymentMethodRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
