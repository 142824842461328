import React, { useEffect, useMemo } from 'react';
import Title from '../../../../components/Title/Title';
import { formTypeConstant } from '../../../../Constants/Constants';
import { Type } from '../../../../components/Form/FormType';
import Translator from '../../../../services/translator';
import '../../index.css';

interface InvoiceContainerProps {
  updateInvoiceInfo: (data: { [key: string]: string }) => void;
  invoiceInfo: { [key: string]: string };
}

const InvoiceContainer = (props: InvoiceContainerProps) => {
  const { invoiceInfo, updateInvoiceInfo } = props;

  const defaultForm = useMemo(
    () => [
      {
        id: 'firstname',
        label: Translator.trans('user.property.firstName'),
        maxLength: 255,
        required: true,
        type: formTypeConstant.TextType,
        value: '',
      },
      {
        id: 'lastname',
        label: Translator.trans('user.property.lastName'),
        maxLength: 255,
        required: true,
        type: formTypeConstant.TextType,
        value: '',
      },
      {
        id: 'company',
        label: Translator.trans('user.property.company'),
        maxLength: 255,
        required: true,
        type: formTypeConstant.TextType,
        value: '',
      },
      {
        id: 'vatNumber',
        label: Translator.trans('accounting.invoice.property.vatNumber'),
        maxLength: 255,
        required: true,
        type: formTypeConstant.TextType,
        value: '',
      },
      {
        id: 'street',
        label: Translator.trans('address.property.street'),
        maxLength: 255,
        required: true,
        type: formTypeConstant.TextType,
        value: '',
      },
      {
        id: 'streetNumber',
        label: Translator.trans('address.property.streetNumber'),
        maxLength: 255,
        required: true,
        type: formTypeConstant.TextType,
        value: '',
      },
      {
        id: 'city',
        label: Translator.trans('address.property.city'),
        maxLength: 255,
        required: true,
        type: formTypeConstant.TextType,
        value: '',
      },
      {
        id: 'zipCode',
        label: Translator.trans('address.property.zipCode'),
        maxLength: 255,
        required: true,
        type: formTypeConstant.TextType,
        value: '',
      },
      {
        id: 'state',
        label: Translator.trans('address.property.areaLevel1'),
        maxLength: 255,
        required: false,
        type: formTypeConstant.TextType,
        value: '',
      },
      {
        id: 'country',
        label: Translator.trans('address.property.country'),
        maxLength: 255,
        required: false,
        type: formTypeConstant.CountryType,
        value: '',
      },
    ],
    []
  );

  useEffect(() => {
    let newInvoice = {};
    if (!invoiceInfo) {
      defaultForm.map((form) => (newInvoice[form.id] = form.value));
    } else {
      newInvoice = invoiceInfo;
    }

    updateInvoiceInfo(newInvoice);
  }, [defaultForm, invoiceInfo, updateInvoiceInfo]);

  const handleChangeInvoice = (event, question) => {
    let newInvoiceInfo = { ...invoiceInfo };
    newInvoiceInfo[question] = event;

    updateInvoiceInfo(newInvoiceInfo);
  };

  return (
    <>
      <Title value={Translator.trans('accounting.invoice.title')} />

      <div>
        {invoiceInfo &&
          defaultForm.map((question, index) => (
            <Type
              key={index}
              uniqueId={question.id}
              question={question}
              value={invoiceInfo[question.id]}
              onChange={handleChangeInvoice}
            />
          ))}
      </div>
    </>
  );
};

export default InvoiceContainer;
