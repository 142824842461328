export default class ShopAnalyticsModel {
  googleAnalytics: string;
  facebookPixel: string;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.googleAnalytics = data.googleAnalytics;
    this.facebookPixel = data.facebookPixel;
  }
}
