import QuestionModel from './QuestionModel';

export default class CustomerFormModel {
  id: number;
  name: string;
  questions: QuestionModel[];

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.questions = data.questions.map(
      (question) => new QuestionModel(question)
    );
  }
}
