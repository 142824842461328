import ProviderDataModel from '../ProviderData/ProviderDataModel';
import UserModel from '../UserModel';
import AddressModel from '../AddressModel';
import AmountModel from '../AmountModel';
import { createProviderData } from '../ProviderData/ProviderDataCreator';

export const paymentProviders = {
  ADYEN: 'ADYEN',
  AUTHORIZE_NET: 'AUTHORIZE_NET',
  CLOVER: 'CLOVER',
  DEBUG: 'DEBUG',
  EMISYS: 'EMISYS',
  NONE: 'NONE',
  OGONE: 'OGONE',
  PAY_BE: 'PAY_BE',
  PAYCONIQ: 'PAYCONIQ',
  PAYFACTO: 'PAYFACTO',
  PAYFLOW: 'PAYFLOW',
  PAYPAL: 'PAYPAL',
  SQUAREUP: 'SQUAREUP',
  STRIPE: 'STRIPE',
  VIVAWALLET: 'VIVAWALLET',
  WORLDLINE: 'WORLDLINE',
};

export default class OrderConfirmModel {
  id: number;
  signature: string;
  orderId: string;
  provider: keyof typeof paymentProviders;
  status: string;
  customer: UserModel | null;
  billingAddress: AddressModel | null;
  providerData: ProviderDataModel | null;
  returnUrl: string;
  amount: AmountModel | null;
  code?: string | null;
  isNoPayment?: boolean;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.id = data.id;
    this.signature = data.signature;
    this.orderId = data.orderId?.toString();
    this.provider = data.provider;
    this.status = data.status;
    this.customer = data.customer ? new UserModel(data.customer) : null;
    this.billingAddress = data.customer?.billingAddress
      ? new AddressModel(data.customer.billingAddress)
      : null;
    this.amount = data.amount ? new AmountModel(data.amount) : null;
    this.providerData = data.providerData
      ? createProviderData(data.provider, data.providerData)
      : null;
    this.returnUrl = data.returnUrl;
    this.isNoPayment = data.code === 'no_payment_required';
  }
}
