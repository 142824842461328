import React from 'react';
import 'antd/dist/reset.css';
import ReactDOM from 'react-dom/client';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import RoutesPage from './app/Routes/Routes';
import getStore from './redux/store/store';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { getBaseUrl } from './services/parseUrl';
import './index.css';
import './style/emisys-antd.css';

dayjs.extend(localizedFormat);
library.add(fas);
export const store = getStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <BrowserRouter basename={getBaseUrl(window.location.pathname)}>
      <RoutesPage />
    </BrowserRouter>
  </Provider>
);
