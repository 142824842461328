export default class UserModel {
  id: number;
  aliasName: string | null;
  namePrefix: string | null;
  firstname: string | null;
  lastname: string | null;
  nameSuffix: string | null;
  fullName: string | null;
  email: string | null;
  birthday: Date | null;
  gender: string | null;
  cellularPhoneNumber: string | null;
  phonenumber: string | null;
  companyName: string | null;
  tshirtSize: string | null;
  picture: string | null;
  nationalIdentificationNumber: string | null;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.id = data.id;
    this.aliasName = data.aliasName;
    this.namePrefix = data.namePrefix;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.nameSuffix = data.nameSuffix;
    this.fullName = data.fullname;
    this.email = data.email;
    this.birthday = new Date(data.birthday);
    this.gender = data.gender;
    this.phonenumber = data.phonenumber;
    this.cellularPhoneNumber = data.cellularPhoneNumber;
    this.companyName = data.companyName;
    this.tshirtSize = data.tshirtSize;
    this.picture = data.picture?.path;
    this.nationalIdentificationNumber = data.nationalIdentificationNumber;
  }
}
