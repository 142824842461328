export default class ShopAuthProviderModel {
  googleAppId: string;
  facebookAppId: string;
  appleServiceId: string;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.googleAppId = data.googleAppId;
    this.facebookAppId = data.facebookAppId;
    this.appleServiceId = data.appleServiceId;
  }
}
