import React from 'react';
import { Modal } from 'antd';
import BasketRecap from '../BasketRecap/BasketRecap';

interface BasketRecapModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BasketRecapModal = (props: BasketRecapModalProps) => (
  <Modal
    open={props.isOpen}
    closable={false}
    onCancel={props.onClose}
    footer={null}
    width={960}
    centered
  >
    <BasketRecap editable={false} onClose={props.onClose} />
  </Modal>
);
export default BasketRecapModal;
