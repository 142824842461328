import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { deleteAllBasketRequest } from '../../redux/actions/basket.actions';
import { Tooltip } from 'antd';
import ModalTimer from '../Modal/ModalTimer';
import Translator from '../../services/translator';
import { checkReduxResponse } from '../../services/httpService';
import './Timer.css';

class Timer extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      currentBasket: {},
      seconds: 0,
      minutes: 0,
      expireTime: null,
      isTimeExpired: false,
    };
    this.timer = 0;
  }

  startTimer() {
    this.timer = setInterval(this.countDown, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentBasket !== this.props.currentBasket) {
      this.setLoadingBasket(this.props.currentBasket.loading);
      if (this.props.currentBasket.basket && this.props.currentBasket.loading) {
        this.setState({ expireTime: null });
        clearInterval(this.timer);
        this.timer = 0;
      }
      if (checkReduxResponse(this.props.currentBasket, 'basket')) {
        if (
          this.props.currentBasket.basket &&
          this.props.currentBasket.basket.holdToken
        ) {
          let expireTime = new Date(
            this.props.currentBasket.basket.holdToken.expiresAt
          );
          let remaining = this.countRemaining(expireTime);
          this.setState(
            {
              currentBasket: this.props.currentBasket.basket,
              loading: false,
              expireTime: expireTime,
              seconds: this.computeRemainingSeconds(remaining),
              minutes: this.computeRemainingMinutes(remaining),
            },
            () => {
              if (this.timer === 0) {
                this.startTimer();
              }
            }
          );
        } else {
          this.setState({ expireTime: null, loading: false });
        }
      }
    }
  }

  formatRemainingTime = (minutes, seconds) => {
    return ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
  };

  countDown = () => {
    let remaining = this.countRemaining(this.state.expireTime);
    if (remaining <= 0) {
      remaining = 0;
      clearInterval(this.timer);
      this.props.deleteAllBasket();
      this.setState({ isTimeExpired: true });
    }

    let seconds = this.computeRemainingSeconds(remaining);
    let minutes = this.computeRemainingMinutes(remaining);

    this.setState({
      seconds: seconds,
      minutes: minutes,
    });
  };

  countRemaining = (expireTime) => {
    return (expireTime - new Date()) / 1000;
  };

  computeRemainingSeconds = (remainingTime) => {
    return Math.floor(remainingTime % 60);
  };

  computeRemainingMinutes = (remainingTime) => {
    return Math.floor(remainingTime / 60);
  };

  setLoadingBasket(isLoading) {
    this.setState({ loadingItems: isLoading });
  }

  handleClose = () => {
    this.setState({ isTimeExpired: false });
  };

  render() {
    const { mobile } = this.props;
    const { expireTime, minutes, seconds, isTimeExpired } = this.state;

    return (
      expireTime && (
        <div className="timer-aside">
          <div className="timer-container">
            <div className="timer">
              <ClockCircleOutlined className={'clock-icon'} />
              <span>
                {mobile
                  ? this.formatRemainingTime(minutes, seconds)
                  : Translator.trans('timer.time_left', {
                      time: this.formatRemainingTime(minutes, seconds),
                    })}
              </span>
            </div>
            {!mobile && (
              <Tooltip
                placement="bottom"
                title={Translator.trans('timer.tooltip')}
              >
                <InfoCircleOutlined className="icon-info-circle" />
              </Tooltip>
            )}
          </div>
          {isTimeExpired && (
            <ModalTimer isOpen={isTimeExpired} handleClose={this.handleClose} />
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  currentBasket: state.basket.currentBasket,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAllBasket: () => dispatch(deleteAllBasketRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
