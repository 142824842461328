export default class ShopLocaleModel {
  locale: string;
  language: string;
  defaultLanguage: string;
  allowedLanguages: string[];
  currencyCode: string;
  countryCode: string;
  timezone: string;
  antdLocale: any;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.locale = data.locale.replace('_', '-');
    this.language = data.language;
    this.defaultLanguage = data.defaultLanguage;
    this.allowedLanguages = data.allowedLanguages;
    this.currencyCode = data.currencyCode;
    this.countryCode = data.countryCode;
    this.timezone = data.timezone;
    this.antdLocale = null;
  }

  setAntdLocale(locale) {
    this.antdLocale = locale;
  }
}
