import React from 'react';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import { DeleteOutlined } from '@ant-design/icons';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import ProductModel from '../../Models/ProductModel';

type seatsType = any; // implement type of seats.io

interface TicketPlacementProps {
  product: ProductModel;
  onDeselectSeats: (seat: seatsType) => void;
  onSelectSeats: (seat: seatsType) => void;
  quantityMinPerSale: number | null;
  placementEventKey: string;
  selectedSeats: seatsType[];
}

const TicketPlacement = (props: TicketPlacementProps) => {
  const {
    product,
    onSelectSeats,
    onDeselectSeats,
    quantityMinPerSale,
    placementEventKey,
    selectedSeats,
  } = props;

  return (
    <>
      <SeatsioSeatingChart
        workspaceKey={shopConfig.placementMap.seatsioApiWorkspaceKey}
        event={placementEventKey}
        region={shopConfig.placementMap.seatsioApiRegion}
        session="start" //  start a new session (and generate a new hold token).
        onObjectSelected={onSelectSeats}
        onObjectDeselected={onDeselectSeats}
        minSelectedObjects={quantityMinPerSale}
        maxSelectedObjects={product.quantityMaxPerSale}
        objectColor={(object) =>
          object.status === 'free' ? '#ff00a0' : '#cccccc'
        }
      />
      {selectedSeats.length !== 0 && (
        <div className={'selected-seats-container'}>
          {selectedSeats.map((seat, index) => {
            return (
              <>
                <div key={index} className={'selected-seat'}>
                  <div className={'seat'}>
                    <div className="seat-informations">
                      {Translator.trans('ticket.placementSelection', {
                        label: seat.category?.label,
                        parent: <span>{seat.labels.parent}</span>,
                        own: <span>{seat.labels.own}</span>,
                      })}
                    </div>
                    <div className="delete-seat-button">
                      <DeleteOutlined
                        onClick={() => {
                          onDeselectSeats(seat);
                          seat.deselect();
                        }}
                        className={'delete-seat'}
                      />
                    </div>
                  </div>
                </div>
                {index % 2 ? <div className="break-flex" /> : ''}
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TicketPlacement;
