import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { attendeeConstants } from '../constants/attendee.constants';
import { get, getUrl, post } from '../../services/httpService';
import {
  getAttendeeFormSuccess,
  getAttendeeFormFailure,
  postAttendeeFormSuccess,
  postAttendeeFormFailure,
} from '../actions/attendee.actions';
import { shopConfig } from '../../Globals/ShopConfig';
import getBasketSession from '../../services/basketSession';

export async function getAttendeeFormFetch(arg) {
  const url = shopConfig.router.userAttendeeFormUrl;
  return await get(getUrl(url, arg.basketSession));
}

export async function postAttendeeFormFetch(arg) {
  const url = shopConfig.router.userAttendeeFormSaveUrl;
  return await post(getUrl(url, arg.basketSession), arg.form);
}

export function* fetchGetAttendeeForm() {
  try {
    const form = yield call(getAttendeeFormFetch, {
      basketSession: getBasketSession(),
    });
    yield put(getAttendeeFormSuccess(form));
  } catch (error) {
    yield put(getAttendeeFormFailure(error));
  }
}

export function* fetchPostAttendeeForm(action) {
  try {
    const form = yield call(postAttendeeFormFetch, {
      form: action.form,
      basketSession: getBasketSession(),
    });
    yield put(postAttendeeFormSuccess(form));
  } catch (error) {
    yield put(postAttendeeFormFailure(error));
  }
}

export function* getAttendeeForm() {
  yield takeLatest(
    attendeeConstants.GET_ATTENDEE_FORM_REQUEST,
    fetchGetAttendeeForm
  );
}

export function* postAttendeeForm() {
  yield takeLatest(
    attendeeConstants.POST_ATTENDEE_FORM_REQUEST,
    fetchPostAttendeeForm
  );
}

export default function* attendeeSaga() {
  yield all([fork(getAttendeeForm), fork(postAttendeeForm)]);
}
