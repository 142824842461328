import React from 'react';
import { Link } from 'react-router-dom';
import { CONSTANTSCMS } from '../../Constants/Constants';
import Translator from '../../services/translator';
import audienceLogo from '../../assets/images/audience.png';
import MediaQuery from 'react-responsive';
import './Footer.css';

const Footer = () => (
  <footer className="footer-react">
    <nav className="footerContainer">
      <div id="imageLogofooter">
        <a href="https://www.emisys.com" className="poweredby">
          <img src={audienceLogo} alt="byemisys" />
        </a>
      </div>
      <MediaQuery minWidth={769}>
        <ul className="footerLinks">
          <li>
            <a href="#" className="quantcastModal">
              {Translator.trans('footer.cookies')}
            </a>
          </li>
          <li>
            <Link to={'/page/' + CONSTANTSCMS.TERMSURL}>
              {Translator.trans('footer.cgv')}
            </Link>
          </li>
          <li>
            <Link to={'/page/' + CONSTANTSCMS.LEGALURL}>
              {Translator.trans('footer.legal_notice')}
            </Link>
          </li>
          <li>
            <Link to={'/page/' + CONSTANTSCMS.POLICYURL}>
              {Translator.trans('footer.policy')}
            </Link>
          </li>
          <li>
            <Link to={'/page/' + CONSTANTSCMS.INFOSURL}>
              {Translator.trans('footer.faq')}
            </Link>
          </li>
        </ul>
      </MediaQuery>
    </nav>
  </footer>
);

export default Footer;
