import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { formatMoney } from '../../services/formatMoney';
import EmisysButton from '../EmisysButton/EmisysButton';
import { postBasketMultipleItemRequest } from '../../redux/actions/basket.actions';
import { dispatchAddToCart } from '../../services/tracking';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import './Drawer.css';

class DrawerFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingButton: false,
      locale: shopConfig.locale.locale,
      currency: shopConfig.locale.currencyCode,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentBasket !== this.props.currentBasket) {
      this.setState({ loadingButton: this.props.currentBasket.loading });
    }
  }

  handleConfirm = () => {
    let multipleItemPayload = [];

    if (this.props.options.length > 0) {
      this.props.options.forEach((option) => {
        multipleItemPayload.push({
          formuleId: option.formule.id,
          quantity: option.quantity,
          sessionId: option.sessionId ? option.sessionId : null,
          project: shopConfig.project.name,
          pos: shopConfig.pos.slug,
        });

        dispatchAddToCart(option.formule.name, option.formule.price);
      });
    } else if (Object.keys(this.props.sessions).length > 0) {
      Object.values(this.props.sessions).forEach((session) => {
        session.forEach((data) => {
          multipleItemPayload.push({
            formuleId: this.props.product.id,
            quantity: data.quantity,
            sessionId: data.id,
            project: shopConfig.project.name,
            pos: shopConfig.pos.slug,
          });

          dispatchAddToCart(this.props.product.name, this.props.product.amount);
        });
      });
    }

    this.props.postBasketMultipleItem({ items: multipleItemPayload });
  };

  render() {
    const { loadingButton } = this.state;
    const { totalAllPrice } = this.props;

    return (
      <div className={'confirm-drawer-container'}>
        <div className={'drawer-total-price'}>
          {formatMoney(this.state.currency, this.state.locale, totalAllPrice)}
        </div>
        <div className={'drawer-btn-add-to-basket'}>
          <EmisysButton
            disabled={
              Object.keys(this.props.sessions).length === 0 &&
              this.props.options.length === 0
            }
            loading={loadingButton}
            onClick={this.handleConfirm}
            icon={
              <ShoppingCartOutlined className={'confirm-drawer-button-icon'} />
            }
          >
            {Translator.trans('product.button.add')}
          </EmisysButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentBasket: state.basket.currentBasket,
});

const mapDispatchToProps = (dispatch) => ({
  postBasketMultipleItem: (items) =>
    dispatch(postBasketMultipleItemRequest(items)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerFooter);
