import { getBasketCookie } from './handleCookies';

const getBasketSession = () => {
  let currentId: string | null = null;
  let currentSignature: string | null = null;
  const basketSession = getBasketCookie();
  const getUrl = new URLSearchParams(window.location.search);

  if (getUrl.has('id') && getUrl.has('signature')) {
    currentId = getUrl.get('id');
    currentSignature = getUrl.get('signature');
  } else if (basketSession) {
    currentId = basketSession.id;
    currentSignature = basketSession.signature;
  }

  if (currentId && currentSignature) {
    return {
      id: currentId,
      signature: currentSignature,
    };
  } else {
    return null;
  }
};

export default getBasketSession;
