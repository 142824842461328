import React, { useCallback, useState } from 'react';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { post } from '../../../services/httpService';
import { showMessageError } from '../../Notification/Notification';
import { shopConfig } from '../../../Globals/ShopConfig';
import { ProviderChildProps } from '../Provider';
import SquareUpModel from '../../../Models/ProviderData/SquareUp/SquareUpModel';
import Translator from '../../../services/translator';
import getBasketSession from '../../../services/basketSession';
import * as Square from '@square/web-sdk';
import './SquareUp.css';
import './../../Modal/Modal.css';

const SquareUp = (props: ProviderChildProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const basketSession = getBasketSession();
  let providerData = null;

  if (
    props.provider?.providerData !== null &&
    props.provider?.providerData instanceof SquareUpModel
  ) {
    providerData = props.provider?.providerData;
  }

  const squareUpSuccess = useCallback(
    (data, buyer) => {
      setLoading(true);
      setErrorMessage('');
      post(shopConfig.router.paymentSquareupValidateUrl, {
        id: props.provider.id,
        signature: props.provider.signature,
        nonce: data.token,
        brand: data.details.card.brand,
        last4: data.details.card.last4,
        exp: data.details.card.expMonth + '/' + data.details.card.expYear,
        buyer: buyer?.token,
      })
        .then(() => {
          props.handleRedirect('/payment/success');
        })
        .catch((error) => {
          setErrorMessage(error.body?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [props]
  );

  const squareUpCancel = useCallback(() => {
    setLoading(true);
    post(shopConfig.router.paymentSquareupCancelUrl, {
      id: props.provider.id,
      signature: props.provider.signature,
    })
      .then(() => {
        if (props.handleClose) {
          props.handleClose();
        } else {
          props.handleRedirect('/payment/failure');
        }
      })
      .catch((error) => {
        setErrorMessage(error.body?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props]);

  function getVerificationDetails() {
    let result: Square.ChargeVerifyBuyerDetails = {
      intent: 'CHARGE',
      amount: props.provider?.amount?.amount.toString(),
      billingContact: {},
      currencyCode: props.provider?.amount?.currency,
    };
    if (props.provider?.billingAddress?.streetLine) {
      result.billingContact.addressLines = [
        props.provider?.billingAddress?.streetLine,
      ];
    }
    if (props.provider?.customer?.lastname) {
      result.billingContact.familyName = props.provider?.customer?.lastname;
    }
    if (props.provider?.customer?.firstname) {
      result.billingContact.givenName = props.provider?.customer?.firstname;
    }
    if (props.provider?.billingAddress?.country) {
      result.billingContact.countryCode =
        props.provider?.billingAddress?.country;
    }
    if (props.provider?.billingAddress?.city) {
      result.billingContact.city = props.provider?.billingAddress?.city;
    }
    return result;
  }

  return (
    <Modal
      className="squareup-modal"
      title={
        <>
          <div className="basketInfo">
            <span>{Translator.trans('modal.payment.squareup.title')}</span>
            <span>#{basketSession?.id}</span>
          </div>
          <Button
            type="primary"
            shape="circle"
            className="close-button"
            icon={<CloseOutlined />}
            onClick={squareUpCancel}
          />
        </>
      }
      closable={false}
      open={props.isOpen}
      onCancel={squareUpCancel}
      footer={null}
      centered
    >
      <div className="shop-modal-body">
        <PaymentForm
          applicationId={providerData?.applicationId}
          cardTokenizeResponseReceived={(response, verifiedBuyer) => {
            squareUpSuccess(response, verifiedBuyer);
          }}
          createVerificationDetails={() => getVerificationDetails()}
          locationId={providerData?.locationId}
        >
          <CreditCard buttonProps={{ isLoading: loading }}>
            {Translator.trans('order.button.confirmAndPay', {
              amount: props.provider?.amount?.formattedPrice,
            })}
          </CreditCard>
        </PaymentForm>

        {errorMessage && <div>{showMessageError('', errorMessage)}</div>}
      </div>
    </Modal>
  );
};

export default SquareUp;
