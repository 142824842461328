import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Menu, MenuProps, Spin } from 'antd';
import Timer from '../../Timer/Timer';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import userDropdown from '../components/userDropdown';
import { getSelfLocaleName } from '../../../services/getLocaleName';
import Translator from '../../../services/translator';
import { CONSTANTSCMS } from '../../../Constants/Constants';
import { handleChangeLanguage } from '../../../services/parseUrl';
import { useDispatch } from 'react-redux';
import { logUserOut } from '../../../redux/actions/users.actions';
import { removeTokenToStorage } from '../../../services/handleTokens';
import './menu.css';

interface TopMenuProps {
  isLoading: boolean;
  userProfile: any;
  currentLang: string;
  languages: {
    lang: string;
    language: string;
  }[];
  isMobile: boolean;
}

const footerUrls = [
  {
    url: '/page/' + CONSTANTSCMS.TERMSURL,
    name: Translator.trans('footer.cgv'),
  },
  {
    url: '/page/' + CONSTANTSCMS.LEGALURL,
    name: Translator.trans('footer.legal_notice'),
  },
  {
    url: '/page/' + CONSTANTSCMS.POLICYURL,
    name: Translator.trans('footer.policy'),
  },
  {
    url: '/page/' + CONSTANTSCMS.INFOSURL,
    name: Translator.trans('footer.faq'),
  },
];

const TopMenu = ({
  isLoading,
  userProfile,
  currentLang,
  languages,
  isMobile,
}: TopMenuProps) => {
  const [dropdownItems, setDropdownItems] = useState([]);
  const dispatch = useDispatch();

  const onLogOut = useCallback(() => {
    dispatch(logUserOut());
    removeTokenToStorage();
  }, [dispatch]);

  const items: MenuProps['items'] = [
    {
      key: 'timer',
      className: 'item-menu-header menu-timer',
      label: <Timer />,
    },
    {
      key: 'translations',
      className: 'dropdown-menu-header languages',
      popupClassName: 'dropdown-menu-header-popup',
      popupOffset: [0, 0],
      label: getSelfLocaleName(currentLang),
      children: languages
        .filter((data) => data.lang !== currentLang.toLowerCase())
        .map((data) => ({
          key: data.lang,
          label: (
            <a href={handleChangeLanguage(window.location.pathname, data.lang)}>
              <span>{capitalizeFirstLetter(data.language)}</span>
            </a>
          ),
        })),
    },
    {
      key: 'account',
      className: 'dropdown-menu-header account',
      popupClassName: 'dropdown-menu-header-popup',
      popupOffset: [0, 0],
      label: !isLoading && (
        <span className="title-my-account">
          {userProfile
            ? userProfile.fullName
            : Translator.trans('account.profile.my_account')}
        </span>
      ),
      icon: isLoading ? (
        <Spin
          indicator={<LoadingOutlined className="header-loading-icon" spin />}
        />
      ) : userProfile?.picture ? (
        <Avatar
          className="avatar-my-account"
          size={30}
          src={userProfile.picture}
        />
      ) : (
        <Avatar
          className="avatar-my-account"
          size={30}
          icon={<UserOutlined />}
        />
      ),
      children: !isLoading ? userDropdown(userProfile, onLogOut) : [],
    },
  ];

  const itemsMobile: MenuProps['items'] = [
    {
      key: 'timer',
      className: 'item-menu-header menu-timer',
      label: <Timer />,
    },
    {
      key: 'mobile',
      className: 'dropdown-menu-header account',
      popupClassName: 'dropdown-menu-header-popup',
      popupOffset: [0, 0],
      label: !isLoading && (
        <span className="title-my-account">
          {userProfile
            ? userProfile.fullName
            : Translator.trans('account.profile.my_account')}
        </span>
      ),
      icon: isLoading ? (
        <Spin
          indicator={<LoadingOutlined className="header-loading-icon" spin />}
        />
      ) : userProfile ? (
        userProfile.picture ? (
          <Avatar
            className="avatar-my-account"
            size={30}
            src={userProfile.picture}
          />
        ) : (
          <Avatar className="avatar-my-account" size={30}>
            {userProfile.fullName.charAt(0)}
          </Avatar>
        )
      ) : (
        <Avatar
          className="avatar-my-account"
          size={30}
          icon={<UserOutlined />}
        />
      ),
      children: dropdownItems,
    },
  ];

  useEffect(() => {
    let dropdownItems = [];

    if (!isLoading) {
      dropdownItems = userDropdown(userProfile, onLogOut);
    }

    dropdownItems.push({
      type: 'divider',
    });

    languages.forEach((data) =>
      dropdownItems.push({
        key: data.lang,
        label: (
          <a href={handleChangeLanguage(window.location.pathname, data.lang)}>
            <span
              style={{
                fontWeight:
                  data.lang === currentLang.toLowerCase() ? 'bold' : 'normal',
              }}
            >
              {capitalizeFirstLetter(data.language)}
            </span>
          </a>
        ),
      })
    );

    dropdownItems.push({
      type: 'divider',
    });

    footerUrls.forEach((url) => {
      dropdownItems.push({
        key: url.url,
        label: (
          <Link to={url.url}>
            <span>{url.name}</span>
          </Link>
        ),
      });
    });

    setDropdownItems(dropdownItems);
  }, [currentLang, isLoading, languages, onLogOut, userProfile]);

  return (
    <Menu
      mode="horizontal"
      selectable={false}
      triggerSubMenuAction={'hover'}
      items={isMobile ? itemsMobile : items}
    />
  );
};

export default TopMenu;
