export default class RequiredItemModel {
  formuleId: number;
  formuleName: string;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.formuleId = data.formuleId;
    this.formuleName = data.formuleName;
  }
}
