export default class SessionOptionModel {
  option: number;
  qtyRemaining: number | null;
  quotaMax: number | null;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.option = data.option;
    this.qtyRemaining = data.qtyRemaining;
    this.quotaMax = data.quotaMax;
  }

  static setSessionOptionModel(options) {
    return options.map((option) => new SessionOptionModel(option));
  }
}
