import ProductModel from './ProductModel';

export default class CategoryModel {
  id: number;
  description: string;
  name: number;
  products: ProductModel[] | undefined;

  /**
   * @param {Object} data
   */
  constructor(data) {
    this.id = data.id;
    this.description = data.description;
    this.name = data.name;
    this.products = data.products?.map((product) => new ProductModel(product));
  }
}
