import React from 'react';
import SessionFiltersDropdown from './SessionFiltersDropdown';
import { sessionFilters } from '../../Constants/Constants';
import Translator from '../../services/translator';
import './Sessions.css';

export type filterType = keyof typeof sessionFilters;
interface SessionFiltersProps {
  filters: { [key in filterType]: filterType[] };
  onHandleFilter: (event: string[], key: string) => void;
}

const SessionFilters = (props: SessionFiltersProps) => (
  <div className={'session-filter-container'}>
    {Object.keys(props.filters).map((filter: filterType, index: number) => {
      if (props.filters[filter].length === 0) {
        return null;
      } else {
        if (filter === sessionFilters.languages) {
          return (
            <SessionFiltersDropdown
              key={index}
              placeholder={Translator.trans('session.filters.languages')}
              filter={props.filters[filter]}
              type={filter}
              onHandleFilter={props.onHandleFilter}
            />
          );
        }

        if (filter === sessionFilters.performers) {
          return (
            <SessionFiltersDropdown
              key={index}
              placeholder={Translator.trans('session.filters.performer')}
              filter={props.filters[filter]}
              type={filter}
              onHandleFilter={props.onHandleFilter}
            />
          );
        }

        if (filter === sessionFilters.places) {
          return (
            <SessionFiltersDropdown
              key={index}
              placeholder={Translator.trans('session.filters.places')}
              filter={props.filters[filter]}
              type={filter}
              onHandleFilter={props.onHandleFilter}
            />
          );
        }

        return null;
      }
    })}
  </div>
);

export default SessionFilters;
